import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Image as ImageFromAntd,
  DatePicker,
  ConfigProvider,
  Empty,
  Divider,
} from "antd";
import { Modal, Spinner } from "react-bootstrap";
import red from "../../../../images/theme/red.png";
import purple from "../../../../images/theme/purple.png";
import orange from "../../../../images/theme/orange.png";
import green from "../../../../images/theme/green.png";
import noImage from "../../../../images/noImage.jpg";
import dayjs from "dayjs";
import { jsPDF } from "jspdf";
import swal from "sweetalert";
import { FaRegHandPointRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./Table.css";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Logout } from "../../../../store/actions/AuthActions";

const RenewClient = () => {
  let { data } = useParams();
  data = JSON.parse(atob(data));
  let { name } = useParams();
  // console.log();
  let Navigate = useNavigate();

  let accessToken = JSON.parse(localStorage.getItem("userDetails"));

  const [TransportalID, setTransportalID] = useState("");
  const [TransportalPass, setTransportalPass] = useState("");
  const [TermResKey, setTermResKey] = useState("");
  const [MerchantID, setMerchantID] = useState("");
  const [APIPass, setAPIPass] = useState("");
  const [MerchantName, setMerchantName] = useState("");

  const [UserData, setUserData] = useState();
  const [ExpireDate, setExpireDate] = useState("");
  const [pageLoading, setPageLoading] = useState(true);

  const [ClientID, setClientID] = useState("");

  const dispatch = useDispatch();
  function onLogout() {
    dispatch(Logout(Navigate));
    // window.location.reload();
  }
  //   GetDetails
  const getUserData = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
        `requirements/${data?.RequirementId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(">PreFilled", result.requirement);
        if (result.requirement) {
          setUserData(result.requirement);
          setExpireDate(dayjs(result.subscriptionEndDate));
          const currentDate = dayjs();
          const subscriptionEndDate = dayjs(result.subscriptionEndDate);

          // Check if subscriptionEndDate is in the past
          if (subscriptionEndDate.isBefore(currentDate)) {
            setStartDate(currentDate); // Store today's date
          } else {
            setStartDate(subscriptionEndDate); // Store subscriptionEndDate
          }
          setCompanyName(result.requirement.companyName);
          setNumberOfUsers(result.requirement.numberOfUsers);
          setSubdomainTitle(result.requirement.subdomainTitle);
          setClientID(result.requirement?.ClientId);
          // Mastercard
          setMerchantID(result.requirement?.MastercardCredential?.merchantId);
          setAPIPass(result.requirement?.MastercardCredential?.apiPassword);
          setMerchantName(
            result.requirement?.MastercardCredential?.merchantName
          );
          // knet
          setTransportalID(result.requirement?.KnetCredential?.transportalId);
          setTransportalPass(
            result.requirement?.KnetCredential?.transportalPassword
          );
          setTermResKey(result.requirement?.KnetCredential?.termResourceKey);
          setPageLoading(false);
        } else if (
          result.message === "unauthorized" ||
          result.message === "token expire"
        ) {
          swal({
            title: "Session Expired!",
            text: `your session has expired! Kindly login again!`,
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              onLogout();
            } else {
              onLogout();
            }
          });
        } else {
          setPageLoading(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const [CompanyName, setCompanyName] = useState("");
  const [NumberOfUsers, setNumberOfUsers] = useState("");
  const [SubdomainTitle, setSubdomainTitle] = useState("");
  const [PrevSubdomainTitle, setPrevSubdomainTitle] = useState("");
  const [SubscriptionId, setSubscriptionId] = useState(
    UserData?.SubscriptionId
  );

  useEffect(() => {
    // Set initial subscription ID from UserData if available
    if (UserData?.SubscriptionId) {
      setSubscriptionId(UserData.SubscriptionId);
    }
  }, [UserData]);

  //   get Subscription
  const [SubscriptionData, setSubscriptionData] = useState([]);
  const getSubscription = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "subscriptions/",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.subscriptions) {
          setSubscriptionData(result.subscriptions);
        } else if (
          result.message === "unauthorized" ||
          result.message === "token expire"
        ) {
          swal({
            title: "Session Expired!",
            text: `your session has expired! Kindly login again!`,
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              onLogout();
            } else {
              onLogout();
            }
          });
        }
      })
      .catch((error) => console.log("error", error));
  };

  // State to store payment methods data
  const [PaymentData, setPaymentData] = useState([]);
  // State to store initially selected payment method IDs
  const [initialPaymentIds, setInitialPaymentIds] = useState([]);
  // State to store currently selected payment method IDs
  const [selectedPaymentIds, setSelectedPaymentIds] = useState([]);
  // State to store selected payment gateway names and IDs
  const [selectedPaymentGateways, setSelectedPaymentGateways] = useState([]);

  useEffect(() => {
    if (UserData?.PaymentMethods?.length) {
      const initialIds = UserData.PaymentMethods.map((method) => method.id);
      const initialGateways = UserData.PaymentMethods.map((method) => ({
        id: method.id,
        name: method.name,
      }));
      setInitialPaymentIds(initialIds);
      setSelectedPaymentIds(initialIds);
      setSelectedPaymentGateways(initialGateways);
    }
  }, [UserData]);

  const getPayment = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "payment-methods/",
        requestOptions
      );
      const result = await response.json();
      setPaymentData(result.paymentMethods);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getPayment();
  }, []);

  const handleCheckboxChange = (id, name) => {
    setSelectedPaymentIds((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((item) => item !== id);
      } else {
        return [...prevSelected, id];
      }
    });

    setSelectedPaymentGateways((prevSelected) => {
      if (prevSelected.some((gateway) => gateway.id === id)) {
        return prevSelected.filter((gateway) => gateway.id !== id);
      } else {
        return [...prevSelected, { id, name }];
      }
    });
  };

  const preparePaymentMethodPayload = () => {
    let add = [];
    let remove = [];

    initialPaymentIds.forEach((id) => {
      if (!selectedPaymentIds.includes(id)) {
        remove.push(id);
      }
    });

    selectedPaymentIds.forEach((id) => {
      if (!initialPaymentIds.includes(id)) {
        add.push(id);
      }
    });

    return { add: add.join(","), remove: remove.join(",") };
  };

  const [RLoad, setRLoad] = useState(false);

  const validateForms = () => {
    let isValid = true;

    selectedPaymentGateways.forEach((gateway) => {
      if (gateway.name === "Knet") {
        if (!TransportalID || !TransportalPass || !TermResKey) {
          isValid = false;
        }
      } else if (gateway.name === "Mastercard") {
        if (!MerchantID || !APIPass || !MerchantName) {
          isValid = false;
        }
      }
    });

    return isValid;
  };

  const [DynamicSubscription, setDynamicSubscription] = useState("");

  const [ShowPaymentVerification, setShowPaymentVerification] = useState(false);
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [DateChange, setDateChange] = useState(1);
  const [RefNo, setRefNo] = useState("");
  const [AmountPaid, setAmountPaid] = useState("");

  // console.log(data);

  const handleRenewClient = () => {
    if (RefNo === "") {
      setPaymentVerificationError("Please enter ref number...");
    } else if (AmountPaid === "") {
      setPaymentVerificationError("Please enter paid amount...");
    } else if (StartDate === "") {
      setPaymentVerificationError("Please select start date...");
    } else if (EndDate === "") {
      setPaymentVerificationError("Please select end date...");
    } else {
      if (validateForms()) {
        setVLoad(true);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

        const raw = JSON.stringify({
          refNo: RefNo,
          amount: AmountPaid,
          subscriptionId: UserData?.Subscription?.id,
          clientId: ClientID,
          startDate:
            dayjs(StartDate).format("MM-DD-YYYY") +
            " " +
            moment().format("hh:mm a"),
          endDate:
            dayjs(EndDate).format("MM-DD-YYYY") +
            " " +
            moment().format("hh:mm a"),
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(
          process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
            "client-payments/add",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
            if (result.message === "Successfully added") {
              setVLoad(false);
              setRLoad(true);
              const payload = preparePaymentMethodPayload();
              console.log("Payload to send:", payload);
              const myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");
              myHeaders.append(
                "Authorization",
                `Bearer ${accessToken.accessToken}`
              );

              const raw = JSON.stringify({
                subscriptionId: SubscriptionId,
                startDate:
                  dayjs(StartDate).format("MM-DD-YYYY") +
                  " " +
                  moment().format("HH:mm") +
                  ":00",
                endDate:
                  dayjs(EndDate).format("MM-DD-YYYY") +
                  " " +
                  moment().format("HH:mm") +
                  ":00",
                paymentMethod: payload,
              });

              const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
              };

              fetch(
                process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
                  `client/renew/${data?.id}`,
                requestOptions
              )
                .then((response) => response.json())
                .then((result) => {
                  console.log(result);
                  if (result.message === "Successfully Renewed Client") {
                    swal(
                      "Renewed!",
                      "Client's account has been successfully renewed",
                      "success"
                    );
                    setRLoad(false);
                    Navigate("/company");
                    setShowPaymentVerification(false);
                  } else if (
                    result.message === "unauthorized" ||
                    result.message === "token expire"
                  ) {
                    swal({
                      title: "Session Expired!",
                      text: `your session has expired! Kindly login again!`,
                      icon: "warning",
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        onLogout();
                      } else {
                        onLogout();
                      }
                    });
                  } else {
                    setRLoad(false);
                    swal(
                      "Oops!",
                      "Something went wrong while renewing client!",
                      "error"
                    );
                  }
                })
                .catch((error) => console.error(error));
            } else {
              swal("Verification Failed!", result.message, "error");
              setVLoad(false);
            }
          })
          .catch((error) => console.error(error));
      } else {
        swal("Error!", "Kindly fill paymnet details", "error");
      }
    }
  };

  useEffect(() => {
    getUserData();
    getSubscription();
  }, [accessToken.accessToken, data?.id]);

  useEffect(() => {
    getSubscription();
    getPayment();
  }, [accessToken.accessToken]);

  //   PDF handling
  const extractFileName = (url) => {
    const parts = url.split("/");
    return parts[parts.length - 1];
  };

  const dataURItoBlob = (dataURI, type) => {
    const byteString = atob(dataURI.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: type });
  };

  const handlePdf = (imageUrl) => {
    const img = new Image();
    img.src = imageUrl.url;
    img.onload = () => {
      const pdf = new jsPDF();
      const marginLeft = 10; // Left margin
      const marginRight = 10; // Right margin
      const marginTop = 10; // Top margin
      const marginBottom = 10; // Bottom margin

      // Calculate available width and height after applying margins
      const availableWidth =
        pdf.internal.pageSize.getWidth() - (marginLeft + marginRight);
      const availableHeight =
        pdf.internal.pageSize.getHeight() - (marginTop + marginBottom);

      // Calculate image dimensions to fit within available space
      const imgWidth = Math.min(
        availableWidth,
        availableHeight * (img.naturalWidth / img.naturalHeight)
      );
      const imgHeight = imgWidth * (img.naturalHeight / img.naturalWidth);

      // Calculate positioning to center image within available space
      const xPos = (pdf.internal.pageSize.getWidth() - imgWidth) / 2;
      const yPos = (pdf.internal.pageSize.getHeight() - imgHeight) / 2;

      // Add image with margins
      pdf.addImage(img, "JPEG", xPos, yPos, imgWidth, imgHeight);
      const pdfDataUri = pdf.output("datauristring");
      const fileName = extractFileName(imageUrl.name);
      const blob = dataURItoBlob(pdfDataUri, "application/pdf");
      const blobUrl = URL.createObjectURL(blob);
      const newTab = window.open(blobUrl);
      newTab.onload = () => {
        newTab.document.title = fileName;
      };
    };
  };

  const handleStartChange = (date, dateString) => {
    setStartDate(date);
  };
  const handleEndChange = (date, dateString) => {
    setDateChange(1);
    setEndDate(date);
  };

  const calcEndDate = () => {
    // Parse the ExpireDate using the format DD-MM-YYYY
    let parsedDate = dayjs(ExpireDate, "DD-MM-YYYY");

    // Get today's date
    const today = dayjs();

    // Check if ExpireDate is in the past
    if (parsedDate.isBefore(today, "day")) {
      // If ExpireDate has passed, use today's date for the calculation
      parsedDate = today;
    }

    // Add subscription duration to the parsedDate (either ExpireDate or today's date)
    const newDate = parsedDate.add(
      DynamicSubscription === ""
        ? UserData?.Subscription?.duration
        : DynamicSubscription?.duration,
      "month"
    );

    // Update the end date
    setEndDate(newDate);
  };

  useEffect(() => {
    calcEndDate();
  }, [ExpireDate, DynamicSubscription]);

  const [VLoad, setVLoad] = useState(false);

  const [PayLoad, setPayLoad] = useState(false);
  const [ULoad, setULoad] = useState(false);

  const [CredError, setCredError] = useState("");

  const isFormPrefilled = (gateway) => {
    if (gateway.name === "Knet") {
      return TransportalID && TransportalPass && TermResKey;
    } else if (gateway.name === "Mastercard") {
      return MerchantID && APIPass && MerchantName;
    }
    return false;
  };

  const allFormsPrefilled = selectedPaymentGateways.every(isFormPrefilled);


  const handleUpdate = async (e, status) => {
    e.preventDefault();
    setCredError(false);

    if (!allFormsPrefilled) {
      setCredError("please fill all the details");
    } else {
      setPayLoad(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);
      const payload = preparePaymentMethodPayload();
      let raw;
      if (
        (selectedPaymentGateways.length === 2 &&
          selectedPaymentGateways[0].name === "Mastercard" &&
          selectedPaymentGateways[1].name === "Knet") ||
        (selectedPaymentGateways.length === 2 &&
          selectedPaymentGateways[0].name === "Knet" &&
          selectedPaymentGateways[1].name === "Mastercard")
      ) {
        let cred = JSON.stringify({
          paymentMethod: payload,
          credentials: {
            knet: {
              transportalId: TransportalID,
              transportalPassword: TransportalPass,
              termResourceKey: TermResKey,
            },
            mastercard: {
              merchantId: MerchantID,
              apiPassword: APIPass,
              merchantName: MerchantName,
            },
          },
        });
        raw = cred;
      } else if (selectedPaymentGateways[0]?.name === "Knet") {
        let cred = JSON.stringify({
          paymentMethod: payload,
          credentials: {
            knet: {
              transportalId: TransportalID,
              transportalPassword: TransportalPass,
              termResourceKey: TermResKey,
            },
          },
        });
        raw = cred;
      } else if (selectedPaymentGateways[0]?.name === "Mastercard") {
        let cred = JSON.stringify({
          paymentMethod: payload,
          credentials: {
            mastercard: {
              merchantId: MerchantID,
              apiPassword: APIPass,
              merchantName: MerchantName,
            },
          },
        });
        raw = cred;
      }

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
          `client/credentials/${data?.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          if (result.message === "Successfully Updated") {
            setULoad(false);
            setPayLoad(false);
            swal("Updated!", `Successfully Updated!`, "success").then(() => {
              // getUserData();
            });
          } else if (
            result.message === "unauthorized" ||
            result.message === "token expire"
          ) {
            swal({
              title: "Session Expired!",
              text: `your session has expired! Kindly login again!`,
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                onLogout();
              } else {
                onLogout();
              }
            });
          } else {
            //
            swal("Error", result.message, "error");
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  const [PaymentVerficationStatus, setPaymentVerficationStatus] = useState("");
  const [PaymentVerificationError, setPaymentVerificationError] = useState("");

  useEffect(() => {
    if (UserData) {
      const paymentChecklist = UserData.RequirementChecklists.find(
        (el) => el.Checklist?.name === "Payment Received"
      );
      setPaymentVerficationStatus(paymentChecklist?.isCompleted || false);
    }
  }, [UserData]);

  const ChangeSubscription = (e, el) => {
    const newSubscriptionId = e.target.value;
    setSubscriptionId(newSubscriptionId);

    setDynamicSubscription(el);
  };

  const disabledDate = (current) => {
    // Disable dates before today
    return current && current < moment().subtract(1, "days");
  };

  // const [isSubdomainVerfied, setIsSubdomainVerfied] = useState("");
  // const [SubdomainLoader, setSubdomainLoader] = useState("");

  // useEffect(() => {
  //   const verifySubDomain = async () => {
  //     setSubdomainLoader(true);
  //     const requestOptions = {
  //       method: "GET",
  //       redirect: "follow",
  //     };

  //     fetch(
  //       process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
  //         `requirements/subdomain/availability?subdomain=${SubdomainTitle}`,
  //       requestOptions
  //     )
  //       .then((res) => res.json())
  //       .then((result) => {
  //         // console.log(result);
  //         if (result.message === "Available") {
  //           setIsSubdomainVerfied("true");
  //           setSubdomainLoader(false);
  //         } else {
  //           setIsSubdomainVerfied("false");
  //           setSubdomainLoader(false);
  //         }
  //       })
  //       .catch((err) => console.error(err));
  //   };
  //   if (PrevSubdomainTitle !== SubdomainTitle) {
  //     verifySubDomain();
  //   }
  // }, [SubdomainTitle]);

  return (
    <div>
      <div className="relative">
        <form action="" className="animatedView">
          <div className="bg-white p-3 rounded-2xl flex justify-between items-center">
            <h1 className="text-xl text-red-500">Expires On :</h1>
            <p className="text-[#0aa1dd] text-balance font-semibold">
              {ExpireDate === "" || ExpireDate === null
                ? "-"
                : dayjs(ExpireDate).format("DD MMMM YYYY, h:mm a")}
            </p>
          </div>
          {/* Inputs */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-9 gap-y-[24px] mt-4">
            <div className="flex flex-col">
              <label
                htmlFor="name"
                className="text-[#545454] text-[14px] font-semibold"
              >
                Full Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none disabled:bg-gray-100 disabled:cursor-not-allowed"
                placeholder="Mohammed Imran Khan"
                value={
                  UserData
                    ? UserData?.Registration?.User?.fullname
                    : "Not Mentioned"
                }
                onChange={() => {}}
                disabled
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="mobile"
                className="text-[#545454] text-[14px] font-semibold"
              >
                Mobile Number <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                name="mobile"
                id="mobile"
                className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none disabled:bg-gray-100 disabled:cursor-not-allowed"
                placeholder="+965 9865 2376"
                value={
                  UserData
                    ? UserData?.Registration?.User?.mobileNumber
                    : "Not Mentioned"
                }
                onChange={() => {}}
                disabled
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="CompName"
                className="text-[#545454] text-[14px] font-semibold"
              >
                Company Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="CompName"
                id="CompName"
                className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none disabled:bg-gray-100 disabled:cursor-not-allowed"
                placeholder="Alpha Solutions Pvt. Ltd."
                value={CompanyName}
                onChange={(e) => setCompanyName(e.target.value)}
                disabled
              />
            </div>
            {/* Email */}
            <div className="flex flex-col">
              <label
                htmlFor="email"
                className="text-[#545454] text-[14px] font-semibold"
              >
                Email <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none disabled:bg-gray-100 disabled:cursor-not-allowed"
                placeholder="cryptographicsolutions@gmail.com"
                value={
                  UserData
                    ? UserData?.Registration?.User?.email
                    : "Not Mentioned"
                }
                onChange={() => {}}
                disabled
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="users"
                className="text-[#545454] text-[14px] font-semibold"
              >
                Number of Users <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                name="users"
                id="users"
                className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none disabled:bg-gray-100 disabled:cursor-not-allowed"
                placeholder="02"
                value={NumberOfUsers}
                onChange={(e) => setNumberOfUsers(e.target.value)}
                disabled
              />
            </div>
            {/* SubDomain */}
            <div className="flex flex-col relative">
              <div className="flex justify-between items-center">
                <label
                  htmlFor="subDomain"
                  className="text-[#545454] text-[14px] font-semibold"
                >
                  Subdomain Title <span className="text-red-500">*</span>
                </label>
                {/* {SubdomainLoader || PrevSubdomainTitle === SubdomainTitle ? (
                  <></>
                ) : isSubdomainVerfied === "true" ? (
                  <pageLoading className="text-green-600 font-semibold">
                    Subdomain Available.
                  </pageLoading>
                ) : (
                  <p className="text-red-500 font-semibold">
                    Subdomain Not Available.
                  </p>
                )} */}
              </div>
              <input
                type="text"
                name="subDomain"
                id="subDomain"
                className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none disabled:bg-gray-100 disabled:cursor-not-allowed"
                placeholder="bnr.linkwork.in"
                value={SubdomainTitle}
                onChange={(e) => setSubdomainTitle(e.target.value)}
                disabled
              />
              {/* {PrevSubdomainTitle === SubdomainTitle ? (
                ""
              ) : (
                <div className="absolute top-11 right-3">
                  {SubdomainLoader ? (
                    <Spinner animation="border" variant="primary" size="sm" />
                  ) : isSubdomainVerfied === "true" ? (
                    <i className="bi bi-check-circle-fill text-green-500"></i>
                  ) : (
                    <i className="bi bi-x-circle-fill text-red-500"></i>
                  )}
                </div>
              )} */}
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="subDomain"
                className="text-[#545454] text-[14px] font-semibold"
              >
                Company Address <span className="text-red-500">*</span>
              </label>
              <textarea
                name="subDomain"
                id="companyAddress"
                className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none disabled:bg-gray-100 disabled:cursor-not-allowed"
                placeholder="No,99 Street name, Area name, Thaluk, District name, State, Country - Pin code"
                rows={3}
                value={UserData ? UserData?.companyAddress : "Not Mentioned"}
                onChange={() => {}}
                disabled
              />
            </div>
            {/* SM */}
            <div className="flex flex-col">
              <label
                htmlFor="subDomain"
                className="text-[#545454] text-[14px] font-semibold"
              >
                Social Media
              </label>
              <div className="bg-white p-[17px] rounded-lg flex items-center gap-3">
                {data && UserData?.socialMediaAccount?.includes(",") ? (
                  UserData?.socialMediaAccount
                    ?.split(",")
                    .map((link, index) => (
                      <Link key={index} to={link.trim()} target="_blank">
                        {link.includes("twitter") ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="50"
                            height="50"
                            viewBox="0 0 36 36"
                            fill="none"
                          >
                            <path
                              d="M27.5625 0H8.4375C3.7776 0 0 3.7776 0 8.4375V27.5625C0 32.2224 3.7776 36 8.4375 36H27.5625C32.2224 36 36 32.2224 36 27.5625V8.4375C36 3.7776 32.2224 0 27.5625 0Z"
                              fill="white"
                            />
                            <path
                              d="M27.5625 0H8.4375C3.7776 0 0 3.7776 0 8.4375V27.5625C0 32.2224 3.7776 36 8.4375 36H27.5625C32.2224 36 36 32.2224 36 27.5625V8.4375C36 3.7776 32.2224 0 27.5625 0Z"
                              fill="#1D9BF0"
                            />
                            <path
                              d="M28.0648 12.8547C28.0803 13.0778 28.0803 13.301 28.0803 13.5263C28.0803 20.3884 22.8563 28.3025 13.3041 28.3025V28.2982C10.4822 28.3023 7.71877 27.4941 5.34375 25.9702C5.75409 26.0196 6.16641 26.0442 6.57984 26.0452C8.91872 26.047 11.1903 25.2625 13.0296 23.8178C11.9459 23.7973 10.8957 23.4387 10.0258 22.7921C9.15595 22.1455 8.50986 21.2433 8.17791 20.2115C8.95595 20.3615 9.75805 20.3309 10.5224 20.122C8.09972 19.6325 6.35667 17.5039 6.35667 15.0317V14.9659C7.07891 15.3681 7.88721 15.5911 8.71355 15.6158C6.43177 14.0908 5.72836 11.0551 7.1062 8.68162C8.40998 10.286 10.0367 11.5982 11.8806 12.5329C13.7246 13.4677 15.7445 14.004 17.8093 14.1072C17.6034 13.221 17.6339 12.2964 17.8975 11.4256C18.1611 10.5549 18.6488 9.76867 19.3116 9.14554C21.4033 7.17932 24.6929 7.28015 26.6591 9.37068C27.8221 9.14107 28.9374 8.71472 29.9569 8.10998C29.5692 9.31241 28.758 10.3331 27.674 10.9821C28.7035 10.8608 29.7088 10.5852 30.6562 10.1646C29.9593 11.2084 29.0818 12.1192 28.0648 12.8547Z"
                              fill="white"
                            />
                          </svg>
                        ) : link.includes("facebook") ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="50"
                            height="50"
                            viewBox="0 0 40 41"
                            fill="none"
                          >
                            <path
                              d="M40 20.4302C40 9.38455 31.0456 0.430176 20 0.430176C8.95437 0.430176 0 9.38455 0 20.4302C0 30.4127 7.31375 38.6869 16.875 40.1872V26.2114H11.7969V20.4302H16.875V16.0239C16.875 11.0114 19.8609 8.24267 24.4294 8.24267C26.6175 8.24267 28.9062 8.6333 28.9062 8.6333V13.5552H26.3844C23.8998 13.5552 23.125 15.0969 23.125 16.6786V20.4302H28.6719L27.7852 26.2114H23.125V40.1872C32.6862 38.6869 40 30.4128 40 20.4302Z"
                              fill="#1877F2"
                            />
                          </svg>
                        ) : link.includes("instagram") ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 28.87 28.87"
                            width="50"
                            height="50"
                          >
                            <defs>
                              <linearGradient
                                id="a"
                                x1="-1.84"
                                x2="32.16"
                                y1="30.47"
                                y2="-3.03"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop
                                  offset="0"
                                  stopColor="#fed576"
                                  className="stopColorfed576 svgShape"
                                ></stop>
                                <stop
                                  offset=".26"
                                  stopColor="#f47133"
                                  className="stopColorf47133 svgShape"
                                ></stop>
                                <stop
                                  offset=".61"
                                  stopColor="#bc3081"
                                  className="stopColorbc3081 svgShape"
                                ></stop>
                                <stop
                                  offset="1"
                                  stopColor="#4c63d2"
                                  className="stopColor4c63d2 svgShape"
                                ></stop>
                              </linearGradient>
                            </defs>
                            <g
                              data-name="Layer 2"
                              fill="#000000"
                              className="color000 svgShape"
                            >
                              <g
                                data-name="Layer 1"
                                fill="#000000"
                                className="color000 svgShape"
                              >
                                <rect
                                  width="28.87"
                                  height="28.87"
                                  fill="url(#a)"
                                  rx="6.48"
                                  ry="6.48"
                                ></rect>
                                <g
                                  data-name="&amp;lt;Group&amp;gt;"
                                  fill="#000000"
                                  className="color000 svgShape"
                                >
                                  <path
                                    fill="#ffffff"
                                    d="M10 5h9c.2.1.5.1.7.2a4.78 4.78 0 0 1 3.8 3.3 8 8 0 0 1 .3 1.5v8.8a6.94 6.94 0 0 1-1.2 3.1 5.51 5.51 0 0 1-4.5 1.9h-7.5a5.49 5.49 0 0 1-3.7-1.2A5.51 5.51 0 0 1 5 18.14v-7a7.57 7.57 0 0 1 .1-1.5 4.9 4.9 0 0 1 3.8-4.3zm-3.1 9.5v3.9a3.42 3.42 0 0 0 3.7 3.7q3.9.15 7.8 0c2.3 0 3.6-1.4 3.7-3.7q.15-3.9 0-7.8a3.52 3.52 0 0 0-3.7-3.7q-3.9-.15-7.8 0a3.42 3.42 0 0 0-3.7 3.7z"
                                    data-name="&amp;lt;Compound Path&amp;gt;"
                                    className="colorfff svgShape"
                                  ></path>
                                  <path
                                    fill="#ffffff"
                                    d="M9.64 14.54a4.91 4.91 0 0 1 4.9-4.9 5 5 0 0 1 4.9 4.9 4.91 4.91 0 0 1-4.9 4.9 5 5 0 0 1-4.9-4.9zm4.9-3.1a3.05 3.05 0 1 0 3 3 3 3 0 0 0-3-3z"
                                    data-name="&amp;lt;Compound Path&amp;gt;"
                                    className="colorfff svgShape"
                                  ></path>
                                  <path
                                    fill="#ffffff"
                                    d="M18.34 9.44a1.16 1.16 0 0 1 1.2-1.2 1.29 1.29 0 0 1 1.2 1.2 1.2 1.2 0 0 1-2.4 0z"
                                    data-name="&amp;lt;Path&amp;gt;"
                                    className="colorfff svgShape"
                                  ></path>
                                </g>
                              </g>
                            </g>
                          </svg>
                        ) : (
                          <p className="text-red-500 font-semibold">
                            Invalid social media link
                          </p>
                        )}
                      </Link>
                    ))
                ) : data && UserData?.socialMediaAccount ? (
                  <Link to={UserData?.socialMediaAccount} target="_blank">
                    {data &&
                    UserData?.socialMediaAccount?.includes("twitter") ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        viewBox="0 0 36 36"
                        fill="none"
                      >
                        <path
                          d="M27.5625 0H8.4375C3.7776 0 0 3.7776 0 8.4375V27.5625C0 32.2224 3.7776 36 8.4375 36H27.5625C32.2224 36 36 32.2224 36 27.5625V8.4375C36 3.7776 32.2224 0 27.5625 0Z"
                          fill="white"
                        />
                        <path
                          d="M27.5625 0H8.4375C3.7776 0 0 3.7776 0 8.4375V27.5625C0 32.2224 3.7776 36 8.4375 36H27.5625C32.2224 36 36 32.2224 36 27.5625V8.4375C36 3.7776 32.2224 0 27.5625 0Z"
                          fill="#1D9BF0"
                        />
                        <path
                          d="M28.0648 12.8547C28.0803 13.0778 28.0803 13.301 28.0803 13.5263C28.0803 20.3884 22.8563 28.3025 13.3041 28.3025V28.2982C10.4822 28.3023 7.71877 27.4941 5.34375 25.9702C5.75409 26.0196 6.16641 26.0442 6.57984 26.0452C8.91872 26.047 11.1903 25.2625 13.0296 23.8178C11.9459 23.7973 10.8957 23.4387 10.0258 22.7921C9.15595 22.1455 8.50986 21.2433 8.17791 20.2115C8.95595 20.3615 9.75805 20.3309 10.5224 20.122C8.09972 19.6325 6.35667 17.5039 6.35667 15.0317V14.9659C7.07891 15.3681 7.88721 15.5911 8.71355 15.6158C6.43177 14.0908 5.72836 11.0551 7.1062 8.68162C8.40998 10.286 10.0367 11.5982 11.8806 12.5329C13.7246 13.4677 15.7445 14.004 17.8093 14.1072C17.6034 13.221 17.6339 12.2964 17.8975 11.4256C18.1611 10.5549 18.6488 9.76867 19.3116 9.14554C21.4033 7.17932 24.6929 7.28015 26.6591 9.37068C27.8221 9.14107 28.9374 8.71472 29.9569 8.10998C29.5692 9.31241 28.758 10.3331 27.674 10.9821C28.7035 10.8608 29.7088 10.5852 30.6562 10.1646C29.9593 11.2084 29.0818 12.1192 28.0648 12.8547Z"
                          fill="white"
                        />
                      </svg>
                    ) : data &&
                      UserData?.socialMediaAccount?.includes("facebook") ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        viewBox="0 0 40 41"
                        fill="none"
                      >
                        <path
                          d="M40 20.4302C40 9.38455 31.0456 0.430176 20 0.430176C8.95437 0.430176 0 9.38455 0 20.4302C0 30.4127 7.31375 38.6869 16.875 40.1872V26.2114H11.7969V20.4302H16.875V16.0239C16.875 11.0114 19.8609 8.24267 24.4294 8.24267C26.6175 8.24267 28.9062 8.6333 28.9062 8.6333V13.5552H26.3844C23.8998 13.5552 23.125 15.0969 23.125 16.6786V20.4302H28.6719L27.7852 26.2114H23.125V40.1872C32.6862 38.6869 40 30.4128 40 20.4302Z"
                          fill="#1877F2"
                        />
                      </svg>
                    ) : data &&
                      UserData?.socialMediaAccount?.includes("instagram") ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 28.87 28.87"
                        width="50"
                        height="50"
                      >
                        <defs>
                          <linearGradient
                            id="a"
                            x1="-1.84"
                            x2="32.16"
                            y1="30.47"
                            y2="-3.03"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop
                              offset="0"
                              stopColor="#fed576"
                              className="stopColorfed576 svgShape"
                            ></stop>
                            <stop
                              offset=".26"
                              stopColor="#f47133"
                              className="stopColorf47133 svgShape"
                            ></stop>
                            <stop
                              offset=".61"
                              stopColor="#bc3081"
                              className="stopColorbc3081 svgShape"
                            ></stop>
                            <stop
                              offset="1"
                              stopColor="#4c63d2"
                              className="stopColor4c63d2 svgShape"
                            ></stop>
                          </linearGradient>
                        </defs>
                        <g
                          data-name="Layer 2"
                          fill="#000000"
                          className="color000 svgShape"
                        >
                          <g
                            data-name="Layer 1"
                            fill="#000000"
                            className="color000 svgShape"
                          >
                            <rect
                              width="28.87"
                              height="28.87"
                              fill="url(#a)"
                              rx="6.48"
                              ry="6.48"
                            ></rect>
                            <g
                              data-name="&amp;lt;Group&amp;gt;"
                              fill="#000000"
                              className="color000 svgShape"
                            >
                              <path
                                fill="#ffffff"
                                d="M10 5h9c.2.1.5.1.7.2a4.78 4.78 0 0 1 3.8 3.3 8 8 0 0 1 .3 1.5v8.8a6.94 6.94 0 0 1-1.2 3.1 5.51 5.51 0 0 1-4.5 1.9h-7.5a5.49 5.49 0 0 1-3.7-1.2A5.51 5.51 0 0 1 5 18.14v-7a7.57 7.57 0 0 1 .1-1.5 4.9 4.9 0 0 1 3.8-4.3zm-3.1 9.5v3.9a3.42 3.42 0 0 0 3.7 3.7q3.9.15 7.8 0c2.3 0 3.6-1.4 3.7-3.7q.15-3.9 0-7.8a3.52 3.52 0 0 0-3.7-3.7q-3.9-.15-7.8 0a3.42 3.42 0 0 0-3.7 3.7z"
                                data-name="&amp;lt;Compound Path&amp;gt;"
                                className="colorfff svgShape"
                              ></path>
                              <path
                                fill="#ffffff"
                                d="M9.64 14.54a4.91 4.91 0 0 1 4.9-4.9 5 5 0 0 1 4.9 4.9 4.91 4.91 0 0 1-4.9 4.9 5 5 0 0 1-4.9-4.9zm4.9-3.1a3.05 3.05 0 1 0 3 3 3 3 0 0 0-3-3z"
                                data-name="&amp;lt;Compound Path&amp;gt;"
                                className="colorfff svgShape"
                              ></path>
                              <path
                                fill="#ffffff"
                                d="M18.34 9.44a1.16 1.16 0 0 1 1.2-1.2 1.29 1.29 0 0 1 1.2 1.2 1.2 1.2 0 0 1-2.4 0z"
                                data-name="&amp;lt;Path&amp;gt;"
                                className="colorfff svgShape"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                    ) : (
                      <p className="text-red-500 font-semibold">
                        Invalid social media link
                      </p>
                    )}
                  </Link>
                ) : (
                  <p className="text-red-500 font-semibold">
                    User has not passed any social media links
                  </p>
                )}
              </div>
            </div>
            {/* Company Logo */}
            <div className="flex flex-col">
              <label
                htmlFor="subDomain"
                className="text-[#545454] text-[14px] font-semibold"
              >
                Company Logo <span className="text-red-500">*</span>
              </label>
              <div className="">
                <ImageFromAntd
                  src={
                    data && UserData?.logoUrl !== null
                      ? UserData?.logoUrl
                      : noImage
                  }
                  alt=""
                  className="w-[102px] h-[94px] object-cover rounded"
                  style={{ width: 102, height: 94 }}
                />
              </div>
            </div>
          </div>
          {/* Radio/Checkbox/Theme */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-9 gap-y-4 mt-[24px]">
            {/* Documents */}
            <div>
              <label className="text-[#545454] text-[14px] font-semibold">
                Required Documents
              </label>
              {data && UserData?.Documents.length > 0 ? (
                UserData?.Documents.map((el, i) => {
                  return (
                    <div
                      className="bg-white p-3 rounded-xl flex justify-between items-center mb-2.5"
                      key={i}
                    >
                      <h6 className="text-[#A1A1A1] text-[14px] font-[500]">
                        {el.name}
                      </h6>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handlePdf(el);
                          //setShowDocument(true);
                          // setDocumentImage(el);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M4.5 2.25C3.90326 2.25 3.33097 2.48705 2.90901 2.90901C2.48705 3.33097 2.25 3.90326 2.25 4.5V13.5C2.25 14.0967 2.48705 14.669 2.90901 15.091C3.33097 15.5129 3.90326 15.75 4.5 15.75H13.5C14.0967 15.75 14.669 15.5129 15.091 15.091C15.5129 14.669 15.75 14.0967 15.75 13.5V11.25C15.75 10.9516 15.8685 10.6655 16.0795 10.4545C16.2905 10.2435 16.5766 10.125 16.875 10.125C17.1734 10.125 17.4595 10.2435 17.6705 10.4545C17.8815 10.6655 18 10.9516 18 11.25V13.5C18 14.6935 17.5259 15.8381 16.682 16.682C15.8381 17.5259 14.6935 18 13.5 18H4.5C3.30653 18 2.16193 17.5259 1.31802 16.682C0.474106 15.8381 0 14.6935 0 13.5V4.5C0 3.30653 0.474106 2.16193 1.31802 1.31802C2.16193 0.474106 3.30653 0 4.5 0H6.75C7.04837 0 7.33452 0.118527 7.5455 0.329505C7.75647 0.540484 7.875 0.826631 7.875 1.125C7.875 1.42337 7.75647 1.70952 7.5455 1.92049C7.33452 2.13147 7.04837 2.25 6.75 2.25H4.5ZM11.25 2.25C10.9516 2.25 10.6655 2.13147 10.4545 1.92049C10.2435 1.70952 10.125 1.42337 10.125 1.125C10.125 0.826631 10.2435 0.540484 10.4545 0.329505C10.6655 0.118527 10.9516 0 11.25 0H16.875C17.1734 0 17.4595 0.118527 17.6705 0.329505C17.8815 0.540484 18 0.826631 18 1.125V6.75C18 7.04837 17.8815 7.33452 17.6705 7.5455C17.4595 7.75647 17.1734 7.875 16.875 7.875C16.5766 7.875 16.2905 7.75647 16.0795 7.5455C15.8685 7.33452 15.75 7.04837 15.75 6.75V3.84075L12.0465 7.5465C11.9419 7.6511 11.8177 7.73407 11.6811 7.79068C11.5444 7.84729 11.3979 7.87642 11.25 7.87642C11.1021 7.87642 10.9556 7.84729 10.8189 7.79068C10.6823 7.73407 10.5581 7.6511 10.4535 7.5465C10.3489 7.4419 10.2659 7.31773 10.2093 7.18106C10.1527 7.0444 10.1236 6.89792 10.1236 6.75C10.1236 6.60208 10.1527 6.4556 10.2093 6.31894C10.2659 6.18227 10.3489 6.0581 10.4535 5.9535L14.1593 2.25H11.25Z"
                            fill="#A1A1A1"
                          />
                        </svg>
                      </button>
                    </div>
                  );
                })
              ) : (
                <img
                  src={noImage}
                  alt=""
                  className="w-[102px] h-[94px] object-cover rounded"
                />
              )}
            </div>
            {/* Theme */}
            <div>
              <label className="text-[#545454] text-[14px] font-semibold">
                Invoice Theme
              </label>
              <div>
                <img
                  src={
                    data && UserData?.Theme.color === "green"
                      ? green
                      : data && UserData?.Theme.color === "red"
                      ? red
                      : data && UserData?.Theme.color === "purple"
                      ? purple
                      : data && UserData?.Theme.color === "yellow"
                      ? orange
                      : ""
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
          <h2 className="text-[#fff] bg-[#0aa1dd] p-3 mt-5 rounded-lg">
            Update Details :
          </h2>
          {/* <Divider /> */}
          <div className="grid grid-cols-2 gap-20 mt-5">
            {/* Subscription */}
            <div>
              <label className="text-[#545454] text-[14px] font-semibold">
                Subscription
              </label>
              {SubscriptionData.filter((el) => el.isActive).map((el) => (
                <div className="form-check custom-radio mb-2" key={el.id}>
                  <input
                    type="radio"
                    className="form-check-input"
                    id={el.id}
                    value={el.id}
                    name="subscriptionGroup"
                    checked={el.id === SubscriptionId}
                    onChange={(e) => ChangeSubscription(e, el)}
                  />
                  <label
                    className="form-check-label text-[14px] text-[#545454] font-semibold disabled:text-[#545454]"
                    htmlFor={el.id}
                  >
                    {el.duration} Months /{" "}
                    <span className="font-semibold text-[#0aa1dd]">
                      {el.amount} KWD
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </div>
          {/* Payment Cred */}
          <>
            <PaymentCred
              methods={UserData?.PaymentMethods}
              newMethods={selectedPaymentGateways}
              name={name}
              PayLoad={PayLoad}
              handleUpdate={handleUpdate}
              setTransportalPass={setTransportalPass}
              TransportalPass={TransportalPass}
              setTransportalID={setTransportalID}
              TransportalID={TransportalID}
              setTermResKey={setTermResKey}
              TermResKey={TermResKey}
              setMerchantID={setMerchantID}
              MerchantID={MerchantID}
              setAPIPass={setAPIPass}
              APIPass={APIPass}
              setMerchantName={setMerchantName}
              MerchantName={MerchantName}
              status={UserData?.status}
              UserData={UserData}
              allFormsPrefilled={allFormsPrefilled}
              CredError={CredError}
              selectedPaymentIds={selectedPaymentIds}
              PaymentData={PaymentData}
              handleCheckboxChange={handleCheckboxChange}
            />
          </>
        </form>
        <div className="flex justify-end mt-[24px] lg:mt-[50px] mb-5 md:mb-0 fixed -bottom-12 right-0 bg-white p-3 w-full shadow-2xl border">
          {allFormsPrefilled ? (
            <button
              className={`uppercase bg-[#0aa1dd] text-white p-3 px-8 rounded-lg font-semibold text-[14px]`}
              onClick={(e) => {
                e.preventDefault();
                if (selectedPaymentIds?.length <= 0) {
                  swal(
                    "Warning",
                    "Please select at least one payment method to renew client!",
                    "warning"
                  );
                } else {
                  PaymentVerficationStatus && setShowPaymentVerification(true);
                  setPaymentVerificationError(
                    `please verify payment to re-new ${name}!`
                  );
                }
              }}
            >
              Re-new Account
            </button>
          ) : (
            <p className="text-red-500 font-semibold tracking-wide">
              Fill all the required fields to renew client.
            </p>
          )}
        </div>
      </div>
      <PaymentVerification
        name={name}
        DynamicSubscription={DynamicSubscription}
        ShowPaymentVerification={ShowPaymentVerification}
        setShowPaymentVerification={setShowPaymentVerification}
        Subscription={UserData?.Subscription}
        handleStartChange={handleStartChange}
        handleEndChange={handleEndChange}
        StartDate={StartDate}
        setStartDate={setStartDate}
        EndDate={EndDate}
        setEndDate={setEndDate}
        DateChange={DateChange}
        setRefNo={setRefNo}
        RefNo={RefNo}
        AmountPaid={AmountPaid}
        setAmountPaid={setAmountPaid}
        VLoad={VLoad}
        PaymentVerificationError={PaymentVerificationError}
        handleRenewClient={handleRenewClient}
        RLoad={RLoad}
        disabledDate={disabledDate}
        ExpireDate={ExpireDate}
      />
    </div>
  );
};

export default RenewClient;

export const PaymentVerification = ({
  ShowPaymentVerification,
  setShowPaymentVerification,
  name,
  Subscription,
  handleStartChange,
  handleEndChange,
  StartDate,
  setStartDate,
  EndDate,
  setEndDate,
  RLoad,
  setAmountPaid,
  AmountPaid,
  setRefNo,
  RefNo,
  VLoad,
  DynamicSubscription,
  PaymentVerificationError,
  handleRenewClient,
  disabledDate,
  ExpireDate,
}) => {
  return (
    <Modal
      show={ShowPaymentVerification}
      onHide={() => {
        setShowPaymentVerification(false);
      }}
    >
      {/* {console.log(DynamicSubscription)} */}
      <Modal.Header closeButton>
        <Modal.Title>{name.replace("_", " ")}'s Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body className="flex flex-col gap-3">
        <div className="flex flex-col">
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Ref No. <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            id="RefNo"
            className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none shadow-inner"
            placeholder="Ref No..."
            value={RefNo}
            onChange={(e) => setRefNo(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Amount Paid <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            id="AmountPaid"
            className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none shadow-inner"
            placeholder="eg. 499"
            value={AmountPaid}
            onChange={(e) => setAmountPaid(e.target.value)}
          />
        </div>
        <div>
          <div className="bg-gray-50 rounded-xl border border-dashed border-gray-700">
            <h4 className="pt-1.5 pl-2 text-[#545454] flex items-center gap-2">
              <FaRegHandPointRight />
              Subscription
            </h4>
            <ul>
              <li className="text-[#0aa1dd] font-semibold ml-10 mb-1.5 list-disc">
                {DynamicSubscription === ""
                  ? Subscription?.duration
                  : DynamicSubscription?.duration}{" "}
                Months /{" "}
                <span className="font-semibold text-[#0aa1dd]">
                  {DynamicSubscription === ""
                    ? Subscription?.amount
                    : DynamicSubscription?.amount}{" "}
                  KWD
                </span>
              </li>
            </ul>
          </div>
        </div>
        <ConfigProvider
          theme={{
            components: {
              DatePicker: {
                zIndexPopup: 9999,
              },
            },
            token: {
              colorBgContainer: "rgb(243 244 246)",
            },
          }}
        >
          {/* {console.log(StartDate)} */}
          {/* {console.log(EndDate)} */}
          {/* <div className="w-full flex flex-col">
            <label htmlFor="user" className="text-[#555] text-sm font-semibold">
              Start Date
            </label>
            <div className="relative">
              <DatePicker
                style={{ border: 1 }}
                onChange={handleStartChange}
                value={dayjs(StartDate)}
                className="w-full p-2 px-3 placeholder:text-black placeholder:font-semibold border-none"
                placeholder="dd/mm/yyyy"
                format="DD-MM-YYYY"
                disabledDate={disabledDate}
              />
            </div>
          </div> */}
          {/* Expire At */}
          <div className="w-full flex flex-col">
            <label htmlFor="user" className="text-[#555] text-sm font-semibold">
              End Date
            </label>
            <div className="relative">
              <DatePicker
                onChange={handleEndChange}
                value={StartDate !== "" && dayjs(EndDate)}
                className="w-full p-2 px-3 placeholder:text-black placeholder:font-semibold border-none"
                placeholder="dd/mm/yyyy"
                format="DD-MM-YYYY"
                disabledDate={disabledDate}
              />
            </div>
          </div>
          {/* Button */}
          <button
            className="uppercase bg-[#0aa1dd] text-white p-3 px-8 rounded-lg font-semibold text-[14px]"
            onClick={handleRenewClient}
          >
            {VLoad
              ? "Verifying Payment..."
              : RLoad
              ? "Renewing Client..."
              : "Verify & Re-New"}
          </button>
          {PaymentVerificationError && (
            <p className="text-sm text-red-500 font-semibold animatedView">
              {PaymentVerificationError}
            </p>
          )}
        </ConfigProvider>
      </Modal.Body>
    </Modal>
  );
};

export const PaymentCred = ({
  methods,
  newMethods,
  setShowDocument,
  ShowDocument,
  name,
  PayLoad,
  handleApprove,
  setTransportalID,
  TransportalID,
  setTransportalPass,
  TransportalPass,
  TermResKey,
  setTermResKey,
  setMerchantID,
  MerchantID,
  setAPIPass,
  APIPass,
  setMerchantName,
  MerchantName,
  handleUpdate,
  status,
  UserData,
  allFormsPrefilled,
  CredError,
  selectedPaymentIds,
  PaymentData,
  handleCheckboxChange,
}) => {
  const [filteredMethods, setFilteredMethods] = useState([]);

  useEffect(() => {
    const filterMethods = (newMethods, filterNames) => {
      return newMethods.filter((method) => filterNames.includes(method.name));
    };

    if (newMethods) {
      const result = filterMethods(newMethods, ["Knet", "Mastercard"]);
      setFilteredMethods(result);
    }
  }, [newMethods]);

  return (
    <>
      <div className="mb-32 mt-3 bg-white rounded-2xl p-5">
        {/* Payment Option */}
        <div>
          <label className="text-[#545454] text-[14px] font-semibold">
            Payment Options
          </label>
          {/* {console.log(selectedPaymentIds)} */}
          {PaymentData.map((el) => (
            <div className="form-check custom-checkbox mb-2" key={el.id}>
              <input
                type="checkbox"
                className="form-check-input"
                id={el.name}
                value={el.id}
                name="subscriptionGroup"
                checked={selectedPaymentIds.includes(el.id)}
                onChange={() => handleCheckboxChange(el.id, el.name)}
              />
              <label
                className="form-check-label text-[14px] text-[#545454] font-semibold"
                htmlFor={el.name}
              >
                {el.name}
              </label>
            </div>
          ))}
        </div>
        <div
          className={`${
            newMethods?.length > 1
              ? "grid grid-cols-1 lg:grid-cols-2 gap-5"
              : "grid grid-cols-1 gap-5"
          }`}
        >
          {filteredMethods.map((method) => (
            <div key={method.id} className="payment-method">
              <h3 className="text-[#0aa1dd]">Payment Method: {method.name}</h3>
              <hr />
              {method.name === "Knet" ? (
                <div className="flex flex-col gap-4 animatedView">
                  <div className="flex flex-col gap-2 w-full">
                    <label
                      htmlFor="name"
                      className="text-[#545454] text-[14px] font-semibold"
                    >
                      Transportal ID <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="TransportalID"
                      value={TransportalID}
                      className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none bg-gray-100"
                      placeholder="Transportal ID"
                      onChange={(e) => setTransportalID(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col gap-2 w-full">
                    <label
                      htmlFor="name"
                      className="text-[#545454] text-[14px] font-semibold"
                    >
                      Transportal Password{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="password"
                      name="name"
                      id="TransportalPass"
                      value={TransportalPass}
                      className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none bg-gray-100"
                      placeholder="*********"
                      onChange={(e) => setTransportalPass(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col gap-2 w-full">
                    <label
                      htmlFor="name"
                      className="text-[#545454] text-[14px] font-semibold"
                    >
                      Term Resource Key <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="TermResKey"
                      value={TermResKey}
                      className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none bg-gray-100"
                      placeholder="Enter Term Resource Key"
                      onChange={(e) => setTermResKey(e.target.value)}
                    />
                  </div>
                </div>
              ) : method.name === "Mastercard" ? (
                <div className="flex flex-col gap-4 animatedView">
                  <div className="flex flex-col gap-2 w-full">
                    <label
                      htmlFor="name"
                      className="text-[#545454] text-[14px] font-semibold"
                    >
                      Merchant ID <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="MerchantID"
                      value={MerchantID}
                      className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none bg-gray-100"
                      placeholder="Enter Merchant ID"
                      onChange={(e) => setMerchantID(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col gap-2 w-full">
                    <label
                      htmlFor="name"
                      className="text-[#545454] text-[14px] font-semibold"
                    >
                      API Password <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="password"
                      name="name"
                      id="APIPass"
                      value={APIPass}
                      className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none bg-gray-100"
                      placeholder="*********"
                      onChange={(e) => setAPIPass(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col gap-2 w-full">
                    <label
                      htmlFor="name"
                      className="text-[#545454] text-[14px] font-semibold"
                    >
                      Merchant Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="MerchantName"
                      value={MerchantName}
                      className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none bg-gray-100"
                      placeholder="Enter Merchant Name"
                      onChange={(e) => setMerchantName(e.target.value)}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
        {CredError && (
          <div className="my-2 animatedView text-red-500 text-xs">
            {CredError}
          </div>
        )}
        <div className="mt-5 flex justify-end">
          <button
            className={`uppercase bg-[#0aa1dd] text-white py-3 px-16 rounded-lg font-semibold text-[14px]`}
            onClick={(e) => handleUpdate(e, status)}
            // disabled={Loading}
          >
            {PayLoad ? (
              <div className="px-16">
                <Spinner animation="border" variant="light" size="sm" />
              </div>
            ) : allFormsPrefilled ? (
              "Update"
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </>
  );
};
