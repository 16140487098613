import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, createSearchParams } from "react-router-dom";
import { Tabs, Select, ConfigProvider } from "antd";
import { Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { Image } from "antd";
import { useDispatch } from "react-redux";
import { Logout } from "../../../store/actions/AuthActions";

const AppProfile = () => {
  let accessToken = JSON.parse(localStorage.getItem("userDetails"));
  let Navigate = useNavigate();
  const [Data, setData] = useState();
  const [Loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  function onLogout() {
    dispatch(Logout(Navigate));
    // window.location.reload();
  }

  const getProfile = async () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
        `users/${accessToken.user.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.user) {
          setData(result.user);
          setTermsAndConditions(
            result?.user?.Client?.preferences?.termsAndConditions
          );
          let Prefillhours =
            result?.user?.Client?.preferences?.defaultInvoiceExpiryDuration;
          setExpiryDuration(Prefillhours / 3600000);
          setLoading(false);
        } else if (
          result.message === "unauthorized" ||
          result.message === "token expire" ||
          result.message === "token expired"
        ) {
          swal({
            title: "Session Expired!",
            text: `your session has expired! Kindly login again!`,
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              onLogout();
            } else {
              onLogout();
            }
          });
        }
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    getProfile();
  }, [accessToken.accessToken, accessToken.user.id]);

  const [TermsAndConditions, setTermsAndConditions] = useState("");
  const [ExpiryDuration, setExpiryDuration] = useState("");

  const handleConvertMiliseconds = (value) => {
    setExpiryDuration(value);
  };

  const handleUpdateRefrences = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    const raw = JSON.stringify({
      termsAndConditions: TermsAndConditions,
      defaultInvoiceExpiryDuration: ExpiryDuration * 60 * 60 * 1000,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
        `client/preferences/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        swal("Updated", result.message, "success");
        getProfile();
      })
      .catch((error) => console.error(error));
  };

  const days = () => {
    let days = [];
    for (let index = 1; index <= 64; index++) {
      days.push({ value: index, label: index + " Hours" });
    }
    return days;
  };

  const items = [
    {
      key: "1",
      label: "Profile",
      children: (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 animatedView">
          {/* Contact */}
          <div className="bg-white p-3 rounded-lg">
            <div className="flex items-center justify-between">
              <h4 className="text-[#0aa1dd] text-[25px]">Contact</h4>
              <button
                onClick={() =>
                  Navigate(
                    `/profile/${Data && Data.id}/${Data && Data.email}/${
                      Data && Data.mobileNumber
                    }/${Data && Data.fullname}/update-user`
                  )
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M14.033 0.000234916C13.8216 -0.00341406 13.6117 0.03548 13.4156 0.114603C13.2196 0.193727 13.0414 0.311463 12.8918 0.460808L12.1895 1.18467L16.8198 5.83603L17.5221 5.11288C17.6736 4.96166 17.7938 4.78206 17.8758 4.58437C17.9578 4.38668 18 4.17476 18 3.96074C18 3.74673 17.9578 3.53481 17.8758 3.33712C17.7938 3.13942 17.6736 2.95983 17.5221 2.80861L15.1961 0.482573C14.887 0.175113 14.4691 0.00181398 14.033 0.000234916ZM11.3994 1.82146L10.3017 2.8746L15.1301 7.7015L16.2495 6.67012L11.3994 1.82146ZM9.59866 3.64269L2.0067 11.167C1.92044 11.2112 1.84454 11.2731 1.784 11.3487C1.72347 11.4244 1.67969 11.512 1.65554 11.6058L0.0303998 17.1138C-0.00629362 17.2327 -0.00983976 17.3594 0.020143 17.4803C0.0501258 17.6011 0.112502 17.7115 0.200559 17.7995C0.288616 17.8875 0.39902 17.9499 0.519888 17.9799C0.640756 18.0098 0.767512 18.0063 0.886515 17.9696L6.39543 16.3457C6.52048 16.327 6.63792 16.2741 6.73473 16.1928C6.83154 16.1115 6.90395 16.005 6.94393 15.8851L14.4277 8.46888L13.3968 7.43821L5.71489 15.1395L2.62052 16.0382L1.96175 15.3803L2.90565 12.1556L10.5215 4.56384L9.59866 3.64269ZM11.2667 5.33193L3.56372 13.055L4.6825 13.2958L4.8363 14.3279L12.5617 6.62589L11.2667 5.33193Z"
                    fill="#0AA1DD"
                  />
                </svg>
              </button>
            </div>
            <div className="flex flex-col gap-[8px] mt-3">
              <div>
                <h6 className="text-[#0aa1dd] text-md">Full Name</h6>
                <p className="text-[#9B9B9A] text-[18px]">
                  {Data && Data.fullname}
                </p>
              </div>
              <div>
                <h6 className="text-[#0aa1dd] text-md">Mobile Number</h6>
                <p className="text-[#9B9B9A] text-[18px]">
                  {Data && Data.mobileNumber}
                </p>
              </div>
              <div>
                <h6 className="text-[#0aa1dd] text-md">Email</h6>
                <p className="text-[#9B9B9A] text-[18px]">
                  {Data && Data.email}
                </p>
              </div>
            </div>
          </div>
          {/* Company And Address */}
          <div className="bg-white p-3 rounded-lg">
            <div className="flex items-center justify-between">
              <h4 className="text-[#0aa1dd] text-[25px]">Company Details</h4>
              <button
                onClick={() =>
                  Navigate({
                    pathname: `/${Data && Data.Client.id}/${
                      Data && Data.Client?.address
                    }/${Data && Data.Client?.name}/update-company`,
                    search: createSearchParams({
                      sm: btoa(Data?.Client?.socialMediaAccount),
                    }).toString(),
                  })
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M14.033 0.000234916C13.8216 -0.00341406 13.6117 0.03548 13.4156 0.114603C13.2196 0.193727 13.0414 0.311463 12.8918 0.460808L12.1895 1.18467L16.8198 5.83603L17.5221 5.11288C17.6736 4.96166 17.7938 4.78206 17.8758 4.58437C17.9578 4.38668 18 4.17476 18 3.96074C18 3.74673 17.9578 3.53481 17.8758 3.33712C17.7938 3.13942 17.6736 2.95983 17.5221 2.80861L15.1961 0.482573C14.887 0.175113 14.4691 0.00181398 14.033 0.000234916ZM11.3994 1.82146L10.3017 2.8746L15.1301 7.7015L16.2495 6.67012L11.3994 1.82146ZM9.59866 3.64269L2.0067 11.167C1.92044 11.2112 1.84454 11.2731 1.784 11.3487C1.72347 11.4244 1.67969 11.512 1.65554 11.6058L0.0303998 17.1138C-0.00629362 17.2327 -0.00983976 17.3594 0.020143 17.4803C0.0501258 17.6011 0.112502 17.7115 0.200559 17.7995C0.288616 17.8875 0.39902 17.9499 0.519888 17.9799C0.640756 18.0098 0.767512 18.0063 0.886515 17.9696L6.39543 16.3457C6.52048 16.327 6.63792 16.2741 6.73473 16.1928C6.83154 16.1115 6.90395 16.005 6.94393 15.8851L14.4277 8.46888L13.3968 7.43821L5.71489 15.1395L2.62052 16.0382L1.96175 15.3803L2.90565 12.1556L10.5215 4.56384L9.59866 3.64269ZM11.2667 5.33193L3.56372 13.055L4.6825 13.2958L4.8363 14.3279L12.5617 6.62589L11.2667 5.33193Z"
                    fill="#0AA1DD"
                  />
                </svg>
              </button>
            </div>
            <div className="flex flex-col gap-[8px] mt-3">
              <div>
                <h6 className="text-[#0aa1dd] text-md">Company Name</h6>
                <p className="text-[#9B9B9A] text-[18px]">
                  {Data?.Client?.name}
                </p>
              </div>
              <div>
                <h6 className="text-[#0aa1dd] text-md">Company Address</h6>
                <p className="text-[#9B9B9A] text-[18px] w-2/3">
                  {Data?.Client?.address}
                </p>
              </div>
            </div>
          </div>
          {/* Social Media */}
          <div className="bg-white p-3 rounded-lg">
            <div className="flex items-center justify-between">
              <h4 className="text-[#0aa1dd] text-[25px]">Social Media</h4>
              <button
                onClick={() =>
                  Navigate({
                    pathname: `/${Data?.Client.id}/${Data?.Client?.address}/${Data?.Client?.name}/social-media`,
                    search: createSearchParams({
                      sm: btoa(Data?.Client?.socialMediaAccount),
                    }).toString(),
                  })
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M14.033 0.000234916C13.8216 -0.00341406 13.6117 0.03548 13.4156 0.114603C13.2196 0.193727 13.0414 0.311463 12.8918 0.460808L12.1895 1.18467L16.8198 5.83603L17.5221 5.11288C17.6736 4.96166 17.7938 4.78206 17.8758 4.58437C17.9578 4.38668 18 4.17476 18 3.96074C18 3.74673 17.9578 3.53481 17.8758 3.33712C17.7938 3.13942 17.6736 2.95983 17.5221 2.80861L15.1961 0.482573C14.887 0.175113 14.4691 0.00181398 14.033 0.000234916ZM11.3994 1.82146L10.3017 2.8746L15.1301 7.7015L16.2495 6.67012L11.3994 1.82146ZM9.59866 3.64269L2.0067 11.167C1.92044 11.2112 1.84454 11.2731 1.784 11.3487C1.72347 11.4244 1.67969 11.512 1.65554 11.6058L0.0303998 17.1138C-0.00629362 17.2327 -0.00983976 17.3594 0.020143 17.4803C0.0501258 17.6011 0.112502 17.7115 0.200559 17.7995C0.288616 17.8875 0.39902 17.9499 0.519888 17.9799C0.640756 18.0098 0.767512 18.0063 0.886515 17.9696L6.39543 16.3457C6.52048 16.327 6.63792 16.2741 6.73473 16.1928C6.83154 16.1115 6.90395 16.005 6.94393 15.8851L14.4277 8.46888L13.3968 7.43821L5.71489 15.1395L2.62052 16.0382L1.96175 15.3803L2.90565 12.1556L10.5215 4.56384L9.59866 3.64269ZM11.2667 5.33193L3.56372 13.055L4.6825 13.2958L4.8363 14.3279L12.5617 6.62589L11.2667 5.33193Z"
                    fill="#0AA1DD"
                  />
                </svg>
              </button>
            </div>
            {/* icons */}
            <div className="mt-3 grid grid-cols-3 gap-2">
              {Data && Data.Client?.socialMediaAccount.includes(",") ? (
                Data.Client?.socialMediaAccount
                  .split(",")
                  .map((link, index) => (
                    <div key={index} className="flex justify-center">
                      {/* {console.log(link.startsWith(" "))} */}
                      {link?.includes("twitter") || link?.includes("X") ? (
                        <Link
                          to={
                            link?.trim().startsWith("http://") ||
                            link?.trim().startsWith("https://")
                              ? link.trim() // If it already has http or https, don't add it
                              : link?.trim().startsWith("www.") ||
                                link?.trim().startsWith("facebook.") ||
                                link?.trim().startsWith("instagram.") ||
                                link?.trim().startsWith("twitter.")
                              ? "https://" + link.trim() // Add https:// if it starts with www. or social media domains
                              : "https://" + link.trim() // Default case to add https:// for any other URL
                          }
                          target="_blank"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 2500 2500"
                            width="80"
                            height="80"
                          >
                            <path
                              d="M0 1250C0 559.64 559.64 0 1250 0s1250 559.64 1250 1250-559.64 1250-1250 1250S0 1940.36 0 1250z"
                              fill="#000"
                            />
                            <path
                              d="M1335.48 1254.04L1920 530h-385.08l-339.82 422.66L857.98 530H500l531.52 659.92L500 1848h382.22l341.88-436.74L1588.98 1848H1960l-624.52-789.96z"
                              fill="#fff"
                            />
                          </svg>
                        </Link>
                      ) : link?.includes("facebook") ? (
                        <Link
                          to={
                            link?.trim().startsWith("http://") ||
                            link?.trim().startsWith("https://")
                              ? link.trim() // If it already has http or https, don't add it
                              : link?.trim().startsWith("www.") ||
                                link?.trim().startsWith("facebook.") ||
                                link?.trim().startsWith("instagram.") ||
                                link?.trim().startsWith("twitter.")
                              ? "https://" + link.trim() // Add https:// if it starts with www. or social media domains
                              : "https://" + link.trim() // Default case to add https:// for any other URL
                          }
                          target="_blank"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="80"
                            height="80"
                            viewBox="0 0 40 41"
                            fill="none"
                          >
                            <path
                              d="M40 20.4302C40 9.38455 31.0456 0.430176 20 0.430176C8.95437 0.430176 0 9.38455 0 20.4302C0 30.4127 7.31375 38.6869 16.875 40.1872V26.2114H11.7969V20.4302H16.875V16.0239C16.875 11.0114 19.8609 8.24267 24.4294 8.24267C26.6175 8.24267 28.9062 8.6333 28.9062 8.6333V13.5552H26.3844C23.8998 13.5552 23.125 15.0969 23.125 16.6786V20.4302H28.6719L27.7852 26.2114H23.125V40.1872C32.6862 38.6869 40 30.4128 40 20.4302Z"
                              fill="#1877F2"
                            />
                          </svg>
                        </Link>
                      ) : link?.includes("instagram") ? (
                        <Link
                          to={
                            link?.trim().startsWith("http://") ||
                            link?.trim().startsWith("https://")
                              ? link.trim() // If it already has http or https, don't add it
                              : link?.trim().startsWith("www.") ||
                                link?.trim().startsWith("facebook.") ||
                                link?.trim().startsWith("instagram.") ||
                                link?.trim().startsWith("twitter.")
                              ? "https://" + link.trim() // Add https:// if it starts with www. or social media domains
                              : "https://" + link.trim() // Default case to add https:// for any other URL
                          }
                          target="_blank"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 28.87 28.87"
                            width="80"
                            height="80"
                          >
                            <defs>
                              <linearGradient
                                id="a"
                                x1="-1.84"
                                x2="32.16"
                                y1="30.47"
                                y2="-3.03"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop
                                  offset="0"
                                  stopColor="#fed576"
                                  className="stopColorfed576 svgShape"
                                ></stop>
                                <stop
                                  offset=".26"
                                  stopColor="#f47133"
                                  className="stopColorf47133 svgShape"
                                ></stop>
                                <stop
                                  offset=".61"
                                  stopColor="#bc3081"
                                  className="stopColorbc3081 svgShape"
                                ></stop>
                                <stop
                                  offset="1"
                                  stopColor="#4c63d2"
                                  className="stopColor4c63d2 svgShape"
                                ></stop>
                              </linearGradient>
                            </defs>
                            <g
                              data-name="Layer 2"
                              fill="#000000"
                              className="color000 svgShape"
                            >
                              <g
                                data-name="Layer 1"
                                fill="#000000"
                                className="color000 svgShape"
                              >
                                <rect
                                  width="28.87"
                                  height="28.87"
                                  fill="url(#a)"
                                  rx="6.48"
                                  ry="6.48"
                                ></rect>
                                <g
                                  data-name="&amp;lt;Group&amp;gt;"
                                  fill="#000000"
                                  className="color000 svgShape"
                                >
                                  <path
                                    fill="#ffffff"
                                    d="M10 5h9c.2.1.5.1.7.2a4.78 4.78 0 0 1 3.8 3.3 8 8 0 0 1 .3 1.5v8.8a6.94 6.94 0 0 1-1.2 3.1 5.51 5.51 0 0 1-4.5 1.9h-7.5a5.49 5.49 0 0 1-3.7-1.2A5.51 5.51 0 0 1 5 18.14v-7a7.57 7.57 0 0 1 .1-1.5 4.9 4.9 0 0 1 3.8-4.3zm-3.1 9.5v3.9a3.42 3.42 0 0 0 3.7 3.7q3.9.15 7.8 0c2.3 0 3.6-1.4 3.7-3.7q.15-3.9 0-7.8a3.52 3.52 0 0 0-3.7-3.7q-3.9-.15-7.8 0a3.42 3.42 0 0 0-3.7 3.7z"
                                    data-name="&amp;lt;Compound Path&amp;gt;"
                                    className="colorfff svgShape"
                                  ></path>
                                  <path
                                    fill="#ffffff"
                                    d="M9.64 14.54a4.91 4.91 0 0 1 4.9-4.9 5 5 0 0 1 4.9 4.9 4.91 4.91 0 0 1-4.9 4.9 5 5 0 0 1-4.9-4.9zm4.9-3.1a3.05 3.05 0 1 0 3 3 3 3 0 0 0-3-3z"
                                    data-name="&amp;lt;Compound Path&amp;gt;"
                                    className="colorfff svgShape"
                                  ></path>
                                  <path
                                    fill="#ffffff"
                                    d="M18.34 9.44a1.16 1.16 0 0 1 1.2-1.2 1.29 1.29 0 0 1 1.2 1.2 1.2 1.2 0 0 1-2.4 0z"
                                    data-name="&amp;lt;Path&amp;gt;"
                                    className="colorfff svgShape"
                                  ></path>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </Link>
                      ) : (
                        <p className="text-red-500 text-md font-semibold">
                          Invalid social media link
                        </p>
                      )}
                    </div>
                  ))
              ) : Data && Data.Client?.socialMediaAccount ? (
                <div>
                  {Data.Client?.socialMediaAccount?.includes("twitter") ? (
                    <Link to={Data.Client?.socialMediaAccount} target="_blank">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                      >
                        <path
                          d="M27.5625 0H8.4375C3.7776 0 0 3.7776 0 8.4375V27.5625C0 32.2224 3.7776 36 8.4375 36H27.5625C32.2224 36 36 32.2224 36 27.5625V8.4375C36 3.7776 32.2224 0 27.5625 0Z"
                          fill="white"
                        />
                        <path
                          d="M27.5625 0H8.4375C3.7776 0 0 3.7776 0 8.4375V27.5625C0 32.2224 3.7776 36 8.4375 36H27.5625C32.2224 36 36 32.2224 36 27.5625V8.4375C36 3.7776 32.2224 0 27.5625 0Z"
                          fill="#1D9BF0"
                        />
                        <path
                          d="M28.0648 12.8547C28.0803 13.0778 28.0803 13.301 28.0803 13.5263C28.0803 20.3884 22.8563 28.3025 13.3041 28.3025V28.2982C10.4822 28.3023 7.71877 27.4941 5.34375 25.9702C5.75409 26.0196 6.16641 26.0442 6.57984 26.0452C8.91872 26.047 11.1903 25.2625 13.0296 23.8178C11.9459 23.7973 10.8957 23.4387 10.0258 22.7921C9.15595 22.1455 8.50986 21.2433 8.17791 20.2115C8.95595 20.3615 9.75805 20.3309 10.5224 20.122C8.09972 19.6325 6.35667 17.5039 6.35667 15.0317V14.9659C7.07891 15.3681 7.88721 15.5911 8.71355 15.6158C6.43177 14.0908 5.72836 11.0551 7.1062 8.68162C8.40998 10.286 10.0367 11.5982 11.8806 12.5329C13.7246 13.4677 15.7445 14.004 17.8093 14.1072C17.6034 13.221 17.6339 12.2964 17.8975 11.4256C18.1611 10.5549 18.6488 9.76867 19.3116 9.14554C21.4033 7.17932 24.6929 7.28015 26.6591 9.37068C27.8221 9.14107 28.9374 8.71472 29.9569 8.10998C29.5692 9.31241 28.758 10.3331 27.674 10.9821C28.7035 10.8608 29.7088 10.5852 30.6562 10.1646C29.9593 11.2084 29.0818 12.1192 28.0648 12.8547Z"
                          fill="white"
                        />
                      </svg>
                    </Link>
                  ) : Data.Client?.socialMediaAccount?.includes("facebook") ? (
                    <Link to={Data.Client?.socialMediaAccount} target="_blank">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="41"
                        viewBox="0 0 40 41"
                        fill="none"
                      >
                        <path
                          d="M40 20.4302C40 9.38455 31.0456 0.430176 20 0.430176C8.95437 0.430176 0 9.38455 0 20.4302C0 30.4127 7.31375 38.6869 16.875 40.1872V26.2114H11.7969V20.4302H16.875V16.0239C16.875 11.0114 19.8609 8.24267 24.4294 8.24267C26.6175 8.24267 28.9062 8.6333 28.9062 8.6333V13.5552H26.3844C23.8998 13.5552 23.125 15.0969 23.125 16.6786V20.4302H28.6719L27.7852 26.2114H23.125V40.1872C32.6862 38.6869 40 30.4128 40 20.4302Z"
                          fill="#1877F2"
                        />
                      </svg>
                    </Link>
                  ) : Data &&
                    Data.Client?.socialMediaAccount?.includes("instagram") ? (
                    <Link to={Data.Client?.socialMediaAccount} target="_blank">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 28.87 28.87"
                        width="36"
                        height="36"
                      >
                        <defs>
                          <linearGradient
                            id="a"
                            x1="-1.84"
                            x2="32.16"
                            y1="30.47"
                            y2="-3.03"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop
                              offset="0"
                              stopColor="#fed576"
                              className="stopColorfed576 svgShape"
                            ></stop>
                            <stop
                              offset=".26"
                              stopColor="#f47133"
                              className="stopColorf47133 svgShape"
                            ></stop>
                            <stop
                              offset=".61"
                              stopColor="#bc3081"
                              className="stopColorbc3081 svgShape"
                            ></stop>
                            <stop
                              offset="1"
                              stopColor="#4c63d2"
                              className="stopColor4c63d2 svgShape"
                            ></stop>
                          </linearGradient>
                        </defs>
                        <g
                          data-name="Layer 2"
                          fill="#000000"
                          className="color000 svgShape"
                        >
                          <g
                            data-name="Layer 1"
                            fill="#000000"
                            className="color000 svgShape"
                          >
                            <rect
                              width="28.87"
                              height="28.87"
                              fill="url(#a)"
                              rx="6.48"
                              ry="6.48"
                            ></rect>
                            <g
                              data-name="&amp;lt;Group&amp;gt;"
                              fill="#000000"
                              className="color000 svgShape"
                            >
                              <path
                                fill="#ffffff"
                                d="M10 5h9c.2.1.5.1.7.2a4.78 4.78 0 0 1 3.8 3.3 8 8 0 0 1 .3 1.5v8.8a6.94 6.94 0 0 1-1.2 3.1 5.51 5.51 0 0 1-4.5 1.9h-7.5a5.49 5.49 0 0 1-3.7-1.2A5.51 5.51 0 0 1 5 18.14v-7a7.57 7.57 0 0 1 .1-1.5 4.9 4.9 0 0 1 3.8-4.3zm-3.1 9.5v3.9a3.42 3.42 0 0 0 3.7 3.7q3.9.15 7.8 0c2.3 0 3.6-1.4 3.7-3.7q.15-3.9 0-7.8a3.52 3.52 0 0 0-3.7-3.7q-3.9-.15-7.8 0a3.42 3.42 0 0 0-3.7 3.7z"
                                data-name="&amp;lt;Compound Path&amp;gt;"
                                className="colorfff svgShape"
                              ></path>
                              <path
                                fill="#ffffff"
                                d="M9.64 14.54a4.91 4.91 0 0 1 4.9-4.9 5 5 0 0 1 4.9 4.9 4.91 4.91 0 0 1-4.9 4.9 5 5 0 0 1-4.9-4.9zm4.9-3.1a3.05 3.05 0 1 0 3 3 3 3 0 0 0-3-3z"
                                data-name="&amp;lt;Compound Path&amp;gt;"
                                className="colorfff svgShape"
                              ></path>
                              <path
                                fill="#ffffff"
                                d="M18.34 9.44a1.16 1.16 0 0 1 1.2-1.2 1.29 1.29 0 0 1 1.2 1.2 1.2 1.2 0 0 1-2.4 0z"
                                data-name="&amp;lt;Path&amp;gt;"
                                className="colorfff svgShape"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </Link>
                  ) : (
                    <p className="text-red-500 font-semibold whitespace-nowrap">
                      Invalid social media link
                    </p>
                  )}
                </div>
              ) : (
                <div className="text-red-500 font-semibold whitespace-nowrap">
                  User has not passed any social media links
                </div>
              )}
            </div>
          </div>
          <div className="bg-white p-3 rounded-lg p-2 h-[15rem] flex justify-between items-start">
            <div className="">
              <h4 className="text-[#0aa1dd] text-[25px]">Password</h4>
              <p className="text-[#9B9B9A] text-[18px]">Change Your Password</p>
            </div>
            <Link
              to="/change-password"
              className="text-[#0aa1dd] mb-5 text-[17px]"
            >
              Change
            </Link>
          </div>
        </div>
      ),
    },
    // {
    //   key: "2",
    //   label: "Preferences",
    //   children: (
    //     <div className="flex flex-col gap-4 animatedView">
    //       {/* Expiry Duration */}
    //       <div>
    //         <h1 className="text-base font-semibold text-black">
    //           Expiry Duration :{" "}
    //         </h1>
    //         <div className="flex gap-5 items-center">
    //           <ConfigProvider
    //             theme={{
    //               token: {
    //                 colorPrimaryHover: "rgba(255,255,255,0)",
    //                 colorPrimary: "rgba(255,255,255,0)",
    //               },
    //             }}
    //           >
    //             <Select
    //               placeholder="Select Expiry Duration"
    //               options={days()}
    //               style={{ width: 180 }}
    //               value={ExpiryDuration + " Hours"}
    //               onChange={handleConvertMiliseconds}
    //             />
    //           </ConfigProvider>
    //         </div>
    //       </div>
    //       {/* T&C */}
    //       <div className="flex flex-col">
    //         <label htmlFor="T&C" className="font-semibold ">
    //           Terms And Conditions:
    //         </label>
    //         <textarea
    //           name="T&C"
    //           id=""
    //           rows="5"
    //           value={TermsAndConditions}
    //           onChange={(e) => setTermsAndConditions(e.target.value)}
    //           className="bg-white p-3 rounded-lg"
    //         ></textarea>
    //       </div>
    //       {/* Button */}

    //       <div className="flex justify-end">
    //         <button
    //           className="bg-[#0aa1dd] p-3 rounded px-5 font-semibold text-white"
    //           onClick={() => handleUpdateRefrences(Data?.Client?.id)}
    //         >
    //           Update Preferences
    //         </button>
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div
      style={{
        transform: "scale(.85)",
        transformOrigin: "0 0",
        width: "90vw",
        height: "",
      }}
    >
      <Fragment>
        {/* <PageTitle activeMenu="Profile" motherMenu="App" /> */}
        {Loading ? (
          <div className="flex justify-center items-center h-[95vh] bg-white -m-10">
            <Spinner animation="border" variant="primary" className="mb-20" />
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-12">
              <div className="profile card card-body px-3 pt-3 pb-0">
                <div className="profile-head">
                  <div className="photo-content ">
                    <div className="cover-photo rounded"></div>
                  </div>
                  <div className="profile-info">
                    <div className="profile-photo">
                      <Image
                        src={Data?.Client?.logoUrl}
                        className="img-fluid rounded-circle"
                        style={{
                          border: "2px solid rgba(29, 84, 207, 1)",
                          width: 130,
                          height: 100,
                          objectFit: "cover",
                          background: "white",
                        }}
                        alt="profile"
                      />
                    </div>
                    <div className="profile-details flex justify-between items-center">
                      <div className="profile-name px-3 pt-2">
                        <h4 className="text-primary text- mb-0">
                          {Data && Data.fullname}
                        </h4>
                        <p className="capitalize">
                          {Data && Data.role.replace("_", " ")}
                        </p>
                      </div>
                      {/* <div>
                      <button className="text-base font-semibold text-[#0aa1dd]">
                        Reset Wallet Balance
                      </button>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-3 mx-2">
              <ConfigProvider
                theme={{
                  components: {
                    Tabs: {
                      cardBg: "#fff",
                      itemSelectedColor: "#0aa1dd",
                      inkBarColor: "#0aa1dd",
                      itemHoverColor: "#0aa1dd",
                    },
                  },
                }}
              >
                <Tabs defaultActiveKey="1" items={items} />
              </ConfigProvider>
            </div>
          </div>
        )}
        <div className="row"></div>
      </Fragment>
    </div>
  );
};

export default AppProfile;
