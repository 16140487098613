import React, { useState } from "react";
import { Spinner, ToastContainer } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { Logout } from "../../../store/actions/AuthActions";

const ChangePassword = () => {
  let Navigate = useNavigate();
  let username = JSON.parse(localStorage.getItem("userDetails"));

  //   Load
  const [Loading, setLoading] = useState(false);

  //   State
  const [CurrPassState, setCurrPassState] = useState(true);

  const [CurrPassword, setCurrPassword] = useState("");
  const [InputError, setInputError] = useState("");

  let Domain = window.location.href; // or "admin.domain.com"
  let subdomain;

  if (Domain.startsWith("https://")) {
    subdomain = Domain.split("//")[1].split(".")[0];
  } else {
    subdomain = Domain.split(".")[0];
  }

  const dispatch = useDispatch();
  function onLogout() {
    dispatch(Logout(Navigate));
    // window.location.reload();
  }

  const handleCheckPass = () => {
    setInputError(false);
    if (CurrPassword.length < 6) {
      setInputError("Password must be 6 characters!");
    } else {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("X-client-subdomain", subdomain);

      var raw = JSON.stringify({
        email: username.user.email,
        password: CurrPassword,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "auth/login",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.accessToken) {
            setLoading(false);
            setCurrPassState(!CurrPassState);
          } else if (
            result.message === "unauthorized" ||
            result.message === "token expire"
          ) {
            swal({
              title: "Session Expired!",
              text: `your session has expired! Kindly login again!`,
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                onLogout();
              } else {
                onLogout();
              }
            });
          } else {
            setInputError(
              result.message === "Invalid Email / Password" &&
                "Invalid Password"
            );
            setLoading(false);
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  //   Change password
  const [NewPass, setNewPass] = useState("");
  const [ConfirmNewPass, setConfirmNewPass] = useState("");
  //   Error handling
  const [NewPassError, setNewPassError] = useState();
  const handleChangePass = () => {
    setNewPassError("");
    if (NewPass === "") {
      setNewPassError("please enter your password");
    } else if (ConfirmNewPass === "") {
      setNewPassError("please enter your confirm password");
    } else if (NewPass.length < 6 && ConfirmNewPass.length < 6) {
      setNewPassError("Password must be 6 characters!");
    } else if (NewPass !== ConfirmNewPass) {
      setNewPassError(
        "Passwords do not match. Please make sure both passwords are identical."
      );
    } else {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${username.accessToken}`);

      var raw = JSON.stringify({
        password: ConfirmNewPass,
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
          `users/change-password/${username.user.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.message === "Successfully Updated your password") {
            setLoading(false);
            swal("Updated", "Your password has been Changed!", "success").then(
              () => {
                Navigate("/app-profile");
              }
            );
          } else {
            setNewPassError(result.message);
            setLoading(false);
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  return (
    <div className="flex justify-start md:justify-center animatedView">
      <div className="w-full md:w-1/2">
        <div className="bg-white p-3 rounded-[20px]">
          <div className="">
            <h1 className="text-[#fff] bg-[#0aa1dd] rounded-2xl p-4 m-2 text-xl">
              {CurrPassState ? "Old Password" : "New Password"}
            </h1>
          </div>
          <div className="flex items-center relative my-5">
            {CurrPassState ? (
              <div className="w-full px-5">
                <label htmlFor="document" className="font-semibold">
                  Enter Old Password:
                </label>
                <div className="relative mt-2">
                  <input
                    type="password"
                    className={`border rounded-lg p-2 w-full`}
                    placeholder=""
                    onChange={(e) => setCurrPassword(e.target.value)}
                  />
                  <figcaption className="text-xs text-gray-400 m-1">
                    Your current password is required for security verification.
                  </figcaption>
                  {InputError && InputError.length > 0 && (
                    <div className="absolute top-2 right-2 animatedView">
                      <i className="bi bi-info-circle text-red-500"></i>
                    </div>
                  )}
                </div>
                {InputError && (
                  <div className="text-xs text-red-500 font-semibold animatedView">
                    {InputError}
                  </div>
                )}
                {/* button */}
                <div className="mt-4 flex justify-end">
                  <div className="flex justify-between gap-4">
                    <button
                      className="border border-[#0aa1dd] text-[#0aa1dd] rounded p-2 px-4"
                      onClick={() => Navigate(-1)}
                    >
                      Cancel
                    </button>
                    <button
                      className="p-2.5 bg-[#0aa1dd] rounded-md text-white px-3 flex items-center gap-3"
                      onClick={handleCheckPass}
                    >
                      {Loading ? (
                        <Spinner animation="border" variant="light"></Spinner>
                      ) : (
                        <>
                          Next
                          <span>
                            <i className="bi bi-arrow-right"></i>
                          </span>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mx-5 w-full flex flex-col gap-3 animatedView my-3">
                <div className="relative">
                  <label htmlFor="document" className="font-semibold">
                    Enter New Password:
                  </label>
                  <input
                    type="password"
                    className={`border rounded-lg p-2 w-full`}
                    placeholder="Enter your new password"
                    onChange={(e) => setNewPass(e.target.value)}
                  />
                </div>
                <div className="relative mt-2">
                  <label htmlFor="document" className="font-semibold">
                    Confirm Password:
                  </label>
                  <input
                    type="password"
                    className={`border rounded-lg p-2 w-full`}
                    placeholder="Confirm your new password"
                    onChange={(e) => setConfirmNewPass(e.target.value)}
                  />
                  <figcaption className="text-xs text-gray-400 m-1">
                    Password must be atleast 6 charachters.
                  </figcaption>
                </div>
                {NewPassError && (
                  <div className="text-xs m-1 text-red-500 animatedView font-semibold">
                    {NewPassError}
                  </div>
                )}
                {/* Button */}
                <div className=" flex justify-end">
                  <div className="flex justify-between gap-4">
                    <button
                      className="border border-[#0aa1dd] text-[#0aa1dd] rounded p-2 px-4"
                      onClick={() => Navigate(-1)}
                    >
                      Cancel
                    </button>
                    <button
                      className="p-2.5 bg-[#0aa1dd] rounded-md text-white px-3 flex items-center gap-3"
                      onClick={handleChangePass}
                    >
                      {Loading ? (
                        <Spinner animation="border" variant="light"></Spinner>
                      ) : (
                        <>
                          Change Password
                          <span>
                            <i className="bi bi-check2-all"></i>
                          </span>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ChangePassword;
