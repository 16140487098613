// import { Empty } from "antd";
// import React, { useEffect, useState } from "react";
// import { Modal, Spinner } from "react-bootstrap";
// import { toast, ToastContainer } from "react-toastify";
// import swal from "sweetalert";

// const Subscription = () => {
//   let accessToken = JSON.parse(localStorage.getItem("userDetails"));
//   // Subscription
//   const [InputError, setInputError] = useState(false);
//   const [Subscription, setSubscription] = useState([]);
//   const [showSubsModal, setshowSubsModal] = useState(false);

//   // Load
//   const [Load, setLoad] = useState(true);

//   const getSubscription = async (yes, no) => {
//     setLoad(yes);
//     var myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");
//     myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

//     var requestOptions = {
//       method: "GET",
//       headers: myHeaders,
//       redirect: "follow",
//     };

//     await fetch(
//       process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "subscriptions/",
//       requestOptions
//     )
//       .then((response) => response.json())
//       .then((result) => {
//         // console.log(result);
//         setSubscription(result.subscriptions);
//         setLoad(no);
//       })
//       .catch((error) => console.log("error", error));
//   };

//   const [SubDuration, setSubDuration] = useState("");
//   const [SubValue, setSubValue] = useState();

//   const createSubscription = async () => {
//     if (SubDuration === "") {
//       setInputError(true);
//     } else if (SubValue === "") {
//       setInputError(true);
//     } else {
//       var myHeaders = new Headers();
//       myHeaders.append("Content-Type", "application/json");
//       myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

//       var raw = JSON.stringify({
//         duration: SubDuration,
//         amount: SubValue,
//       });

//       var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow",
//       };

//       await fetch(
//         process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "subscriptions/new",
//         requestOptions
//       )
//         .then((response) => response.json())
//         .then((result) => {
//           // console.log(result);
//           if (result.message === "Successfully Created") {
//             setshowSubsModal(false);
//             getSubscription();
//             toast.success(`${result.message}`, {
//               position: "top-center",
//               autoClose: 5000,
//               hideProgressBar: false,
//               closeOnClick: true,
//               pauseOnHover: true,
//               draggable: true,
//               progress: undefined,
//             });
//           }
//         })
//         .catch((error) => console.log("error", error));
//     }
//   };

//   const [SubsUpdateModal, setSubsUpdateModal] = useState(false);
//   const [subscriptionIdForUpdate, setSubscriptionIdForUpdate] = useState();

//   const updateSubs = (el) => {
//     setSubDuration(el.duration);
//     setSubValue(el.amount);
//     setSubscriptionIdForUpdate(el.id);
//     setSubsUpdateModal(true);
//   };

//   const updateSubscription = async () => {
//     var myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");
//     myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

//     var raw = JSON.stringify({
//       duration: SubDuration,
//       amount: SubValue,
//     });

//     var requestOptions = {
//       method: "PUT",
//       headers: myHeaders,
//       body: raw,
//       redirect: "follow",
//     };

//     fetch(
//       process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
//         `subscriptions/edit/${subscriptionIdForUpdate}`,
//       requestOptions
//     )
//       .then((response) => response.json())
//       .then((result) => {
//         console.log(result);
//         if (result.message === "Successfully Updated") {
//           toast.success(`${result.message}`, {
//             position: "top-center",
//             autoClose: 5000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//           });
//           setSubsUpdateModal(false);
//           getSubscription();
//         }
//       })
//       .catch((error) => console.log("error", error));
//   };

//   const ActiveSubscription = async (id, status) => {
//     var myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");
//     myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

//     var raw = JSON.stringify({
//       isActive: status === true ? false : true,
//     });

//     var requestOptions = {
//       method: "PUT",
//       headers: myHeaders,
//       body: raw,
//       redirect: "follow",
//     };

//     fetch(
//       process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
//         `subscriptions/edit/${id}`,
//       requestOptions
//     )
//       .then((response) => response.json())
//       .then((result) => {
//         console.log(result);
//         if (result.message === "Successfully Updated") {
//           swal("Updated", result.message, "success");
//           setSubsUpdateModal(false);
//           getSubscription();
//         }
//       })
//       .catch((error) => console.log("error", error));
//   };

//   useEffect(() => {
//     getSubscription(true, false);
//   }, []);

//   return (
//     <div className="w-full">
//       {/* Subscription */}
//       <div className="flex flex-col gap-[17px]">
//         <h4 className="font-semibold text-sm text-black text-[14px]">
//           Subscription
//         </h4>
//         <div className="flex flex-col gap-2.5 bg-white rounded-xl p-3 relative">
//           {Subscription && Subscription.length > 0 ? (
//             Subscription.map((el, i) => {
//               return (
//                 <div key={i} className="border-b mt-2">
//                   <div className="flex gap-11 items-center">
//                     <div className="flex items-center gap-2">
//                       <div>
//                         <input
//                           type="checkbox"
//                           className="form-check-input border-1 border-[#0aa1dd] mb-2"
//                           id={el.id}
//                           // value={el.id}
//                           checked={el.isActive}
//                           name="subscriptionGroup"
//                           onChange={() =>
//                             ActiveSubscription(el.id, el.isActive)
//                           }
//                         />
//                       </div>
//                       <div>
//                         <h6 className="text-[14px] font-semibold mt-1">
//                           {el.duration} Months
//                         </h6>
//                       </div>
//                     </div>
//                     <h6 className="text-[#888] text-[14px] font-semibold">
//                       {el.amount} KWD
//                       <button className="ml-2" onClick={() => updateSubs(el)}>
//                         <i className="bi bi-pencil-square text-[#0aa1dd] text-base"></i>
//                       </button>
//                     </h6>
//                   </div>
//                 </div>
//               );
//             })
//           ) : (
//             <div>
//               <Empty />
//             </div>
//           )}
//           {Load && <Spinner />}
//           <div className="absolute top-2 right-3">
//             <button onClick={() => setshowSubsModal(true)}>
//               <i className="bi bi-plus-square-fill text-[#0aa1dd] text-lg font-semibold"></i>
//             </button>
//           </div>
//         </div>
//       </div>
//       {/* Subscription Modal */}
//       <Modal show={showSubsModal} onHide={() => setshowSubsModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Add Subscription.</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <label htmlFor="document">Enter subscription duration:</label>
//           <div className="relative">
//             <input
//               type="number"
//               className={`${
//                 InputError === true
//                   ? "border border-red-500 placeholder:text-red-500"
//                   : "border border-gray-200"
//               } rounded-lg p-2 w-full`}
//               placeholder="eg. 03"
//               onChange={(e) => setSubDuration(e.target.value)}
//             />
//             {InputError === true && (
//               <div className="absolute top-2 right-2">
//                 <i className="bi bi-info-circle text-red-500"></i>
//               </div>
//             )}
//           </div>
//           <label htmlFor="document" className="mt-2">
//             Enter subscription value:
//           </label>
//           <div className="relative">
//             <input
//               type="text"
//               className={`${
//                 InputError === true
//                   ? "border border-red-500 placeholder:text-red-500"
//                   : "border border-gray-200"
//               } rounded-lg p-2 w-full`}
//               placeholder="eg. 499"
//               onChange={(e) => setSubValue(e.target.value)}
//             />
//             {InputError === true && (
//               <div className="absolute top-2 right-2">
//                 <i className="bi bi-info-circle text-red-500"></i>
//               </div>
//             )}
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <button
//             className={`bg-gray-400 py-2.5 px-5 rounded-lg text-white`}
//             onClick={() => setshowSubsModal(false)}
//           >
//             Close
//           </button>
//           <button
//             className="bg-[#0aa1dd] text-white py-2.5 px-5 rounded-lg"
//             onClick={createSubscription}
//           >
//             Add Subscription
//           </button>
//         </Modal.Footer>
//       </Modal>
//       {/* Subscription Update Modal */}
//       <Modal show={SubsUpdateModal} onHide={() => setSubsUpdateModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Update Subscription.</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <label htmlFor="document" className="mt-2">
//             Enter subscription Duration:
//           </label>
//           <div className="relative">
//             <input
//               type="text"
//               className={`${
//                 InputError === true
//                   ? "border border-red-500 placeholder:text-red-500"
//                   : "border border-gray-200"
//               } rounded-lg p-2 w-full`}
//               placeholder="eg. 499"
//               value={SubDuration}
//               onChange={(e) => setSubDuration(e.target.value)}
//             />
//             {InputError === true && (
//               <div className="absolute top-2 right-2">
//                 <i className="bi bi-info-circle text-red-500"></i>
//               </div>
//             )}
//           </div>
//           <label htmlFor="document" className="mt-2">
//             Enter subscription value:
//           </label>
//           <div className="relative">
//             <input
//               type="text"
//               className={`${
//                 InputError === true
//                   ? "border border-red-500 placeholder:text-red-500"
//                   : "border border-gray-200"
//               } rounded-lg p-2 w-full`}
//               placeholder="eg. 499"
//               value={SubValue}
//               onChange={(e) => setSubValue(e.target.value)}
//             />
//             {InputError === true && (
//               <div className="absolute top-2 right-2">
//                 <i className="bi bi-info-circle text-red-500"></i>
//               </div>
//             )}
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <button
//             className={`bg-gray-400 py-2.5 px-5 rounded-lg text-white`}
//             onClick={() => setSubsUpdateModal(false)}
//           >
//             Close
//           </button>
//           <button
//             className="bg-[#0aa1dd] text-white py-2.5 px-5 rounded-lg"
//             onClick={updateSubscription}
//           >
//             Update Subscription
//           </button>
//         </Modal.Footer>
//       </Modal>
//       <ToastContainer />
//     </div>
//   );
// };

// export default Subscription;

import { Empty } from "antd";
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Logout } from "../../../../store/actions/AuthActions";

const Subscription = () => {
  let accessToken = JSON.parse(localStorage.getItem("userDetails"));
  // Subscription
  const [InputError, setInputError] = useState(false);
  const [Subscription, setSubscription] = useState([]);
  const [showSubsModal, setshowSubsModal] = useState(false);

  // Load
  const [Load, setLoad] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  function onLogout() {
    dispatch(Logout(navigate));
    // window.location.reload();
  }

  const getSubscription = async () => {
    setLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "subscriptions/",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.subscriptions) {
          setSubscription(result.subscriptions);
          setLoad(false);
        } else if (result.message === "unauthorized" || result.message === "token expire") {
          swal({
            title: "Session Expired!",
            text: `your session has expired! Kindly login again!`,
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              onLogout();
            } else {
              onLogout();
            }
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoad(false);
      });
  };

  const [SubDuration, setSubDuration] = useState("");
  const [SubValue, setSubValue] = useState("");

  const createSubscription = async () => {
    if (SubDuration === "") {
      setInputError("Enter subscription duration...");
    } else if (SubValue === "") {
      setInputError("Enter subscription amount...");
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      var raw = JSON.stringify({
        duration: SubDuration,
        amount: SubValue,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      await fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "subscriptions/new",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          if (result.message === "Successfully Created") {
            setshowSubsModal(false);
            getSubscription();
            toast.success(`${result.message}`, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }else if (result.message === "unauthorized" || result.message === "token expire") {
            swal({
              title: "Session Expired!",
              text: `your session has expired! Kindly login again!`,
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                onLogout();
              } else {
                onLogout();
              }
            });
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  const [SubsUpdateModal, setSubsUpdateModal] = useState(false);
  const [subscriptionIdForUpdate, setSubscriptionIdForUpdate] = useState();

  const updateSubs = (el) => {
    setSubDuration(el.duration);
    setSubValue(el.amount);
    setSubscriptionIdForUpdate(el.id);
    setSubsUpdateModal(true);
  };

  const updateSubscription = async () => {
    setInputError("");
    if (SubDuration === "") {
      setInputError("Enter subscription duration...");
    } else if (SubValue === "") {
      setInputError("Enter subscription amount...");
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      var raw = JSON.stringify({
        duration: SubDuration,
        amount: SubValue,
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
          `subscriptions/edit/${subscriptionIdForUpdate}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.message === "Successfully Updated") {
            toast.success(`${result.message}`, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setInputError("");
            setSubsUpdateModal(false);
            getSubscription();
          }else if (result.message === "unauthorized" || result.message === "token expire") {
            swal({
              title: "Session Expired!",
              text: `your session has expired! Kindly login again!`,
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                onLogout();
              } else {
                onLogout();
              }
            });
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  const ActiveSubscription = async (id, status) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    var raw = JSON.stringify({
      isActive: status === true ? false : true,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
        `subscriptions/edit/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.message === "Successfully Updated") {
          swal("Updated", result.message, "success");
          setSubsUpdateModal(false);
          getSubscription();
        }else if (result.message === "unauthorized" || result.message === "token expire") {
          swal({
            title: "Session Expired!",
            text: `your session has expired! Kindly login again!`,
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              onLogout();
            } else {
              onLogout();
            }
          });
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getSubscription();
  }, []);

  return (
    <div className="w-full">
      {/* Subscription */}
      <div className="flex flex-col gap-[17px] mt-5">
        <h4 className="font-semibold  text-black text-[25px]">Subscription</h4>
        <div className="flex flex-col gap-2.5 bg-white rounded-xl p-3  relative">
          <div className="mt-4">
            {Subscription && Subscription.length > 0
              ? Subscription.map((el, i) => {
                  return (
                    <div key={i} className="border-b p-2  mb-2">
                      <div className="flex gap-10 justify-between items-center">
                        <div className="flex items-center gap-2">
                          <div>
                            <input
                              type="checkbox"
                              className="form-check-input border-1 border-[#0aa1dd] mb-2"
                              id={el.id}
                              // value={el.id}
                              checked={el.isActive}
                              name="subscriptionGroup"
                              onChange={() =>
                                ActiveSubscription(el.id, el.isActive)
                              }
                            />
                          </div>
                          <div>
                            <h6 className="text-[14px] font-semibold mt-1">
                              {el.duration} Months
                            </h6>
                          </div>
                        </div>
                        <h6 className="text-[#888] text-[14px] font-semibold">
                          {el.amount} KWD
                          <button
                            className="ml-2"
                            onClick={() => updateSubs(el)}
                          >
                            <i className="bi bi-pencil-square text-[#0aa1dd] text-base"></i>
                          </button>
                        </h6>
                      </div>
                    </div>
                  );
                })
              : !Load && <Empty />}
          </div>
          {Load && <Spinner />}
          <div className="absolute top-2 right-3">
            <button onClick={() => setshowSubsModal(true)}>
              <i className="bi bi-plus-square-fill text-[#0aa1dd] text-lg font-semibold"></i>
            </button>
          </div>
        </div>
      </div>
      {/* Subscription Modal */}
      <Modal show={showSubsModal} onHide={() => setshowSubsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Subscription.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="document">Enter subscription duration:</label>
          <div className="relative">
            <input
              type="number"
              className={`rounded-lg border p-2 w-full`}
              placeholder="eg. 03"
              onChange={(e) => setSubDuration(e.target.value)}
            />
          </div>
          <label htmlFor="document" className="mt-2">
            Enter subscription value:
          </label>
          <div className="relative">
            <input
              type="text"
              className={`rounded-lg border p-2 w-full`}
              placeholder="eg. 499"
              onChange={(e) => setSubValue(e.target.value)}
            />
          </div>
          {InputError && (
            <div className="text-xs font-semibold text-red-500 my-2 animatedView">
              {InputError}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className={`bg-gray-400 py-2.5 px-5 rounded-lg text-white`}
            onClick={() => setshowSubsModal(false)}
          >
            Close
          </button>
          <button
            className={`bg-[#0aa1dd] py-2.5 px-5 rounded-lg text-white`}
            onClick={createSubscription}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
      {/* Subscription Modal */}
      <Modal show={SubsUpdateModal} onHide={() => setSubsUpdateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Subscription.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="document">Enter subscription duration:</label>
          <div className="relative">
            <input
              type="number"
              className={`rounded-lg border p-2 w-full`}
              placeholder="eg. 03"
              value={SubDuration}
              onChange={(e) => setSubDuration(e.target.value)}
            />
          </div>
          <label htmlFor="document" className="mt-2">
            Enter subscription value:
          </label>
          <div className="relative">
            <input
              type="number"
              className={`rounded-lg border p-2 w-full`}
              placeholder="eg. 499"
              value={SubValue}
              onChange={(e) => setSubValue(e.target.value)}
            />
          </div>
          {InputError && (
            <div className="text-xs font-semibold text-red-500 my-2 animatedView">
              {InputError}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className={`bg-gray-400 py-2.5 px-5 rounded-lg text-white`}
            onClick={() => setSubsUpdateModal(false)}
          >
            Close
          </button>
          <button
            className={`bg-[#0aa1dd] py-2.5 px-5 rounded-lg text-white`}
            onClick={updateSubscription}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default Subscription;
