import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import swal from "sweetalert";
import instagram from "./instagram.svg";
import Twitter from "./twitter.svg";
import fb from "./facebook.svg";

const UpdateSocialMedia = () => {
  let { id, address, name } = useParams();
  let [searchParam] = useSearchParams();
  let sm = searchParam.get("sm");
  sm = atob(sm);
  const accessToken = JSON.parse(localStorage.getItem("userDetails"));
  let navigate = useNavigate();

  let Name = name;
  let Address = address;
  const [SocialMedia, setSocialMedia] = useState(sm);
  let urls = SocialMedia.split(",");
  const [Insta, setInsta] = useState("");
  const [Facebook, setFacebook] = useState("");
  const [X, setX] = useState("");

  // Error handling
  const [Error, setError] = useState("");
  const [Loading, setLoading] = useState(false);
  const [FBUrl, setFBUrl] = useState(false);
  const [IGUrl, setIGUrl] = useState(false);
  const [XUrl, setXUrl] = useState(false);

  const combinedUrls = [Insta, Facebook, X].filter(Boolean).join(", ");

  const handleUpdate = () => {
    if (Name === "") {
      setError("name is required");
    } else if (Address === "") {
      setError("address is required");
    } else {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      const raw = JSON.stringify({
        name: Name,
        address: Address,
        socialMediaAccount: combinedUrls,
      });

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + `client/update/${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setLoading(false);
          if (result.message === "Successfully Updated") {
            swal("Done!", "Social Media updated successfully", "success").then(
              () => navigate("/app-profile")
            );
          } else {
            swal("Failed!", "Error updating the Social Media", "error");
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  };

  // Function to update state variables based on URL type
  const updateUrls = () => {
    urls.forEach((url) => {
      const cleanedUrl = url.trim(); // Removes leading and trailing spaces
  
      if (cleanedUrl.includes("instagram")) {
        setInsta(cleanedUrl);
      } else if (cleanedUrl.includes("facebook")) {
        setFacebook(cleanedUrl);
      } else {
        setX(cleanedUrl); // Already trimmed, no need for manual replacement
      }
    });
  };
  

  // Call updateUrls function when component mounts
  useEffect(() => {
    updateUrls();
  }, []);

  // URL validation regex
  const facebookRegex =
    /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9._(\.\?)?]/;
  const instagramRegex =
    /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9._(\.\?)?]/;
  // const xRegex =
  //   /^(https?:\/\/)?(www\.)?(twitter|x)\.com\/[a-zA-Z0-9._(\.\?)?]/;
  const xRegex = /^(https?:\/\/)?(www\.)?(twitter|x)\.com\/[a-zA-Z0-9._]+/;


  return (
    <div>
      <div className="flex justify-start md:justify-center animatedView">
        <div className="w-full md:w-1/2">
          <div className="bg-white p-3 rounded-[20px]">
            <div className="">
              <h1 className="text-[#fff] bg-[#0aa1dd] rounded-md p-3 m-2 text-xl">
                Update Social Media
              </h1>
            </div>
            <div className="flex items-center">
              <div className="mx-1.5 lg:mx-5 w-full animatedView my-5">
                <div className="flex flex-col h-auto justify-between gap-3">
                  <div className="relative">
                    <label htmlFor="document" className="font-semibold">
                      Facebook Account:
                    </label>
                    <div className="flex flex-row-reverse gap-3 items-center">
                      <input
                        type="text"
                        className={`${
                          Error && Error.length > 0
                            ? "border border-red-500 placeholder:text-red-500"
                            : "border border-gray-200"
                        } border rounded-lg p-2 w-full`}
                        placeholder="Enter Url"
                        value={Facebook}
                        onChange={(e) => {
                          setFacebook(e.target.value);
                          const isValid = facebookRegex.test(e.target.value);
                          setFBUrl(!isValid);
                        }}
                      />
                      <img src={fb} alt="" />
                    </div>
                    {FBUrl && (
                      <p className="text-red-500 font-semibold text-xs p-1 mt-1 animatedView">
                        {Facebook === "" ? "" : "please enter valid URL"}
                      </p>
                    )}
                  </div>
                  <div className="relative">
                    <label htmlFor="document" className="font-semibold">
                      Instagram Account:
                    </label>
                    <div className="flex flex-row-reverse gap-3 items-center">
                      <input
                        type="text"
                        className={`${
                          Error && Error.length > 0
                            ? "border border-red-500 placeholder:text-red-500"
                            : "border border-gray-200"
                        } border rounded-lg p-2 w-full`}
                        placeholder="Enter Url"
                        value={Insta}
                        onChange={(e) => {
                          setInsta(e.target.value);
                          const isValid = instagramRegex.test(e.target.value);
                          setIGUrl(!isValid);
                        }}
                      />
                      <img src={instagram} alt="" />
                    </div>
                    {IGUrl && (
                      <p className="text-red-500 font-semibold text-xs p-1 mt-1 animatedView">
                        {Insta === "" ? "" : "please enter valid URL"}
                      </p>
                    )}
                  </div>
                  <div className="relative">
                    <label htmlFor="document" className="font-semibold">
                      X Account:
                    </label>
                    <div className="flex flex-row-reverse gap-3 items-center">
                      <input
                        type="text"
                        className={`${
                          Error && Error.length > 0
                            ? "border border-red-500 placeholder:text-red-500"
                            : "border border-gray-200"
                        } border rounded-lg p-2 w-full`}
                        placeholder="Enter Url"
                        value={X}
                        onChange={(e) => {
                          setX(e.target.value);
                          const isValid = xRegex.test(e.target.value);
                          setXUrl(!isValid);
                        }}
                      />
                      <img src={Twitter} alt="" />
                    </div>
                    {XUrl && (
                      <p className="text-red-500 font-semibold text-xs p-1 mt-1 animatedView">
                        {X === "" ? "" : "please enter valid URL"}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-3 justify-center items-center mt-5">
                  <div className="flex items-center gap-3">
                    <button
                      className="border border-[#0aa1dd] text-[#0aa1dd] rounded p-2.5 px-4"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {Loading ? (
                      <div className="p-2">
                        <Spinner animation="border" size="lg" />
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="flex w-1/2 justify-center items-center gap-2 bg-[#0aa1dd] hover:bg-[#0aa1dd] transition-all duration-200 text-[#fff] font-semibold rounded-md px-3 py-1 m-2 ml-0 cursor-pointer disabled:cursor-not-allowed disabled:bg-[#64a9c5]"
                        onClick={handleUpdate}
                        disabled={
                          (XUrl && X !== "") ||
                          (IGUrl && Insta !== "") ||
                          (FBUrl && Facebook !== "")
                        }
                      >
                        <span className="p-1 text-xl">Update</span>
                      </button>
                    )}
                    {Error && Error.length > 0 && (
                      <p className="text-red-500 font-semibold text-xs p-1 mt-1 animatedView">
                        {Error}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateSocialMedia;
